export const Spinner = () => {
  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
      style={{ zIndex: 100 }}
    >
      <div className="w-12 h-12 border-4 border-solid border-white border-t-transparent rounded-full animate-spin"></div>
    </div>
  );
};
