import { useEffect, useRef, useState } from "react";
import { SideNav } from "../component/SideNav";
import { Order } from "./component/order";
import { OrderList } from "./component/orderlist";
import Notice from "./component/notice";
import { Setting } from "./component/setting";
import { LastOrderType, OrderType } from "../types/orderType";
import {
  getOrder,
  getOrderDetail,
  newOrderCheck,
} from "../@api/service/order/order";
import { formatDate } from "../utils/func";
import { Spinner } from "./component/spinner/spinner";
import { useTabStore } from "../store/tabStore";
import { useStoreStore } from "../store/storeStore";
import { useNavigate } from "react-router-dom";
import { DetailModal } from "./modal/detailModal";
import { DeliveryMatchModal } from "./modal/deilveryMatchModal";
import { AcceptDeliveryModal } from "./modal/acceptDeliveryModal";
import { MyStore } from "./component/myStore";
import { Ticket } from "./component/ticket";
import { Reservation } from "./component/reservation";
import { Shopping } from "./component/shopping";
import { request } from "http";

const Main = () => {
  // 주문탭 밖에서도 주문이 들어왔을 때 모달이 나와야해서 이 state만 main에서 관리
  const [detailModal, setDetailModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(0);
  useEffect(() => {
    console.log("selectedorderid", selectedOrderId);
  }, [selectedOrderId]);
  const [selectedOrderId2, setSelectedOrderId2] = useState(0); // 기존 selectedorderid 를 사용하면 배달 접수중을 누를 때 selectedorderid를 바뀌어서 주문 상세 모달이 같이 튀어나옴
  const [selectedItem, setSelectedItem] = useState<OrderType>({
    orderId: 0,
    hotelId: 0,
    hotelName: "",
    hotelAddress: "",
    hotelPhone: "",
    storeId: 0,
    storeName: "",
    storePhone: "",
    storeAddress: "",
    roomNum: "",
    userName: "",
    userPhone: "",
    deliveryAddress: "",
    deliveryDetailAddress: "",
    orderStatus: "",
    orderItemList: [], // 빈 배열로 초기화
    orderAmount: 0,
    discountAmount: 0,
    deliveryTip: 0,
    payAmount: 0,
    payMethod: "",
    cardName: "",
    cardNumber: "",
    payAuthNo: "",
    payInfo: "",
    deliveryFee: "",
    orderMemo: "",
    deliveryMemo: "",
    orderDate: "",
    reservationDate: "",
    reservationList: "",
    acceptDate: "",
    readyTime: 0,
    estimateTime: "",
    cancelDate: "",
    deliveryStartDate: "",
    deliveryEndDate: "",
    cancelInfo: "",
    isPrint: "",
    orderType: "",
    typeKey: "",
    cretDt: "",
    uptDt: "",
    updateDate: "",
    cancelFormatDate: "",
    expectedPickupTime: "",
    rowNum: 0,
    mainItemName: "",
    itemCount: 0,
    orderStatusNm: "",
    expectedPickupTimeR: "",
  });

  const [tab, setTab] = useState(0);

  const [deliveryMatchModal, setDeliveryMatchModal] = useState(false);
  const [acceptDeliveryModal, setAcceptDeliveryModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const { storeId, setStoreId, voiceCnt } = useStoreStore();
  const storeIdRef = useRef(storeId); // storeId를 추적하는 ref

  useEffect(() => {
    storeIdRef.current = storeId; // storeId가 변경될 때마다 최신 값 업데이트
  }, [storeId]);
  const [lastOrderTime, setLastOrderTime] = useState("");
  const [orderCnt, setOrderCnt] = useState(0);
  const [ticketCnt, setTicketCnt] = useState(0);
  const [reservationCnt, setReservationCnt] = useState(0);
  const [shoppingCnt, setShoppingCnt] = useState(0);

  const [orderList, setOrderList] = useState<OrderType[]>([]);
  const [reservationList, setReservationList] = useState<OrderType[]>([]);
  const [shoppingList, setShoppingList] = useState<OrderType[]>([]);
  const [ticketList, setTicketList] = useState<OrderType[]>([]);
  const lastOrderTimeRef = useRef("");
  const [onLoading, setOnLoading] = useState(false);
  const {
    completeTab,
    setCompleteTab,
    setReservationTab,
    reservationTab,
    ticketTab,
    setTicketTab,
    shoppingTab,
    setShoppingTab,
  } = useTabStore();

  const getShoppingList = async () => {
    try {
      // getRequestType을 사용하여 요청 상태를 가져옴

      // API 요청

      const result = await getOrder(
        storeIdRef.current,
        formatDate(new Date()),
        formatDate(new Date()),
        shoppingTab === "1" ? "complete" : "request",
        "N",
        "쇼핑"
      );
      setShoppingList(result.data);

      setShoppingCnt(result.data.length);
    } catch (error) {
      console.log(error);
    }
  };

  const getTicketList = async () => {
    try {
      // getRequestType을 사용하여 요청 상태를 가져옴

      // API 요청

      const result = await getOrder(
        storeIdRef.current,
        formatDate(new Date()),
        formatDate(new Date()),
        ticketTab === "1" ? "complete" : "request",
        "N",
        "티켓"
      );
      setTicketList(result.data);

      setTicketCnt(result.data.length);
    } catch (error) {
      console.log(error);
    }
  };
  const getReservationList = async () => {
    try {
      // getRequestType을 사용하여 요청 상태를 가져옴

      // API 요청

      const result = await getOrder(
        storeIdRef.current,
        formatDate(new Date()),
        formatDate(new Date()),
        reservationTab === "1" ? "complete" : "request",
        "N",
        "식당"
      );
      setReservationList(result.data);

      setReservationCnt(result.data.length);
    } catch (error) {
      console.log(error);
    }
  };

  const getOrderListApi = async () => {
    try {
      // getRequestType을 사용하여 요청 상태를 가져옴

      // API 요청
      const result = await getOrder(
        storeIdRef.current,
        formatDate(new Date()),
        formatDate(new Date()),
        completeTab === "1" ? "complete" : "request",
        "N"
      );
      setOrderList(result.data);
      setOrderCnt(result.data.length);
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();
  const [temp, setTemp] = useState(false);
  const getNewOrderCheckApi = async () => {
    try {
      const result = await newOrderCheck(storeId);
      // console.log("last", lastOrderTimeRef.current, "현재", result.data.rstDt);
      setLastOrderTime(result.data.rstDt);

      setTemp(true);
      if (
        lastOrderTimeRef.current !== "" &&
        result.data.rstDt &&
        lastOrderTimeRef.current !== result.data.rstDt
      ) {
        setSelectedOrderId2(result.data.orderId);
      }
      // console.log("newOrder", result);
    } catch (error) {
      console.log(error);
    }
  };

  // 주문 상세 조회
  const getOrderDetailApi = async () => {
    setOnLoading(true);
    try {
      const result = await getOrderDetail(selectedOrderId2);
      console.log("메인 주문상세", result);
      setSelectedItem(result.data);
      setOnLoading(false);
    } catch (error) {
      console.log(error);
      setOnLoading(false);
    }
  };

  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  let isFetching = false;

  const fetchOrders = async () => {
    if (isFetching) return; // 중복 호출 방지
    isFetching = true;

    await getShoppingList();
    await getTicketList();
    await getReservationList();
    await getOrderListApi();
    await getNewOrderCheckApi();
    isFetching = false;
  };

  const Sound = () => {
    const audio = new Audio("/sounds/orderSound.mp3");
    audio.play();
  };
  useEffect(() => {
    if (completeTab === "") {
      setCompleteTab("0");
    }
    if (reservationTab === "") {
      setReservationTab("0");
    }
    if (shoppingTab === "") {
      setShoppingTab("0");
    }
    if (ticketTab === "") {
      setTicketTab("0");
    }
  }, []);

  // `polling`과 `tab` 변경을 하나의 useEffect로 통합
  useEffect(() => {
    // API 최초 호출
    fetchOrders();

    // 5초마다 주기적으로 호출
    intervalIdRef.current = setInterval(() => {
      fetchOrders();
    }, 5000);

    // 컴포넌트가 언마운트될 때 interval 해제
    return () => {
      if (intervalIdRef.current) clearInterval(intervalIdRef.current);
    };
  }, [completeTab, reservationTab, shoppingTab, ticketTab]);

  useEffect(() => {
    lastOrderTimeRef.current = lastOrderTime;
  }, [lastOrderTime]);

  useEffect(() => {
    if (selectedOrderId2 !== 0) {
      getOrderDetailApi();
      const playSound = async () => {
        for (let i = 0; i < voiceCnt; i++) {
          const audio = new Audio("/sounds/orderSound.mp3");
          await audio.play();
          // 소리가 끝날 때까지 대기
          await new Promise((resolve) => {
            audio.onended = resolve;
          });
        }
      };
      playSound();
    }
  }, [selectedOrderId2]);

  //주문하면 detailmodal 뜨게하는 부분
  useEffect(() => {
    if (selectedItem.orderId !== 0 && temp) {
      setDetailModal(true);
    }
  }, [selectedItem]);

  // 신규 주문 요청시 flow : 메인에서 getNewOrderCheckApi polling으로 상태 조회 응답값으로 오는 rstdt와 lastOrderTimeRef 값을 비교해서 다르면
  //  getNewOrderCheckApi 응답 안에 있는 orderid를 받아서 getOrderDetailApi() 요청 이 getOrderDetailApi()요청 응답값으로 해당 orderid에 대한 정보를 받는데 이 정보를 selectedItem이라는 변수에 저장
  //  이 변수에 변화가 있으면 modal 띄우기

  return (
    <div className="flex flex-row  w-full">
      {tab !== 0 && detailModal && (
        <DetailModal
          isOpen={detailModal}
          setIsOpen={setDetailModal}
          selectedItem={selectedItem}
          setOrderId={setSelectedOrderId}
          orderId={selectedOrderId}
          setOnLoading={setOnLoading}
          setDeliveryMatchModal={setDeliveryMatchModal}
          selectedOrderId2={selectedOrderId2}
        />
      )}
      {tab !== 0 && acceptDeliveryModal && (
        <AcceptDeliveryModal
          setIsOpen={setAcceptDeliveryModal}
          orderId={selectedOrderId2}
          setOnLoading={setOnLoading}
        />
      )}
      {tab !== 0 && deliveryMatchModal && (
        <DeliveryMatchModal
          isOpen={deliveryMatchModal}
          setIsOpen={setDeliveryMatchModal}
          setAcceptDeliveryModal={setAcceptDeliveryModal}
          orderId={selectedOrderId2}
          setOnLoading={setOnLoading}
        />
      )}
      <SideNav
        orderCnt={orderCnt}
        tab={tab}
        setTab={setTab}
        shoppingCnt={shoppingCnt}
        reservationCnt={reservationCnt}
        ticketCnt={ticketCnt}
      />
      {onLoading && <Spinner />}
      {tab === 0 ? (
        <Order
          acceptDeliveryModal={acceptDeliveryModal}
          setAcceptDeliveryModal={setAcceptDeliveryModal}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          setSelectedOrderId={setSelectedOrderId}
          selectedOrderId={selectedOrderId}
          setDetailModal={setDetailModal}
          detailModal={detailModal}
          getOrderListApi={getOrderListApi}
          setOrderCnt={setOrderCnt}
          orderList={orderList}
          setOnLoading={setOnLoading}
          selectedOrderId2={selectedOrderId2}
          setDeliveryMatchModal={setDeliveryMatchModal}
          deliveryMatchModal={deliveryMatchModal}
        />
      ) : tab === 1 ? (
        <OrderList
          setDetailModal={setDetailModal}
          setSelectedItem={setSelectedItem}
        />
      ) : tab === 3 ? (
        <Notice />
      ) : tab === 4 ? (
        <Setting />
      ) : tab === 5 ? (
        <MyStore />
      ) : tab === 6 ? (
        <Ticket
          acceptDeliveryModal={acceptDeliveryModal}
          setAcceptDeliveryModal={setAcceptDeliveryModal}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          setSelectedOrderId={setSelectedOrderId}
          selectedOrderId={selectedOrderId}
          setDetailModal={setDetailModal}
          detailModal={detailModal}
          getOrderListApi={getOrderListApi}
          setOrderCnt={setTicketCnt}
          orderList={ticketList}
          setOnLoading={setOnLoading}
          selectedOrderId2={selectedOrderId2}
          setDeliveryMatchModal={setDeliveryMatchModal}
          deliveryMatchModal={deliveryMatchModal}
        />
      ) : tab === 7 ? (
        <Reservation
          acceptDeliveryModal={acceptDeliveryModal}
          setAcceptDeliveryModal={setAcceptDeliveryModal}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          setSelectedOrderId={setSelectedOrderId}
          selectedOrderId={selectedOrderId}
          setDetailModal={setDetailModal}
          detailModal={detailModal}
          getOrderListApi={getOrderListApi}
          setOrderCnt={setReservationCnt}
          orderList={reservationList}
          setOnLoading={setOnLoading}
          selectedOrderId2={selectedOrderId2}
          setDeliveryMatchModal={setDeliveryMatchModal}
          deliveryMatchModal={deliveryMatchModal}
        />
      ) : tab === 8 ? (
        <Shopping
          acceptDeliveryModal={acceptDeliveryModal}
          setAcceptDeliveryModal={setAcceptDeliveryModal}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          setSelectedOrderId={setSelectedOrderId}
          selectedOrderId={selectedOrderId}
          setDetailModal={setDetailModal}
          detailModal={detailModal}
          getOrderListApi={getOrderListApi}
          setOrderCnt={setShoppingCnt}
          orderList={shoppingList}
          setOnLoading={setOnLoading}
          selectedOrderId2={selectedOrderId2}
          setDeliveryMatchModal={setDeliveryMatchModal}
          deliveryMatchModal={deliveryMatchModal}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Main;
