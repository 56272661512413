import { useEffect, useState } from "react";
import { getNoticeList } from "../../@api/service/notice/notice";
import { NoticeListType } from "../../types/orderType";

const Notice = () => {
  const [noticeList, setNoticeList] = useState<NoticeListType[]>([]);

  const getNoticeListApi = async () => {
    try {
      const result = await getNoticeList(0);
      console.log("noticeList", result);
      setNoticeList(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNoticeListApi();
  }, []);

  return (
    <div className="flex flex-col pt-8 pl-4 pr-4 w-full">
      <div className="flex flex-col gap-4">
        <div className="text-2xl" style={{ fontFamily: "SUIT-Bold" }}>
          공지사항
        </div>
        <div
          className="flex flex-col pr-4"
          style={{ height: "90%", overflowY: "auto", maxHeight: "800px" }}
        >
          {noticeList.map((item, index) => {
            return (
              <div
                key={index}
                className="flex flex-row justify-between items-center"
                style={{ borderBottom: "1px solid #E3E3E3" }}
              >
                <div className="pt-[13px] pb-[13px] flex flex-row gap-2 items-center">
                  {true && (
                    <div
                      className="flex w-fit h-fit p-1"
                      style={{ backgroundColor: "#D89F65" }}
                    >
                      <div
                        className="text-sm text-white"
                        style={{ fontFamily: "SUIT-Bold" }}
                      >
                        new
                      </div>
                    </div>
                  )}
                  <div
                    className="text-base"
                    style={{ fontFamily: "SUIT-Medium" }}
                  >
                    {/* 2023년 12월 {item}일 업데이트 내역 */}
                    {item.title}
                  </div>
                </div>
                <div
                  className="text-sm opacity-70"
                  style={{ fontFamily: "SUIT-Medium" }}
                >
                  {item.cretDt.split(" ")[0]}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Notice;
