import React, { ReactNode, useState } from "react";
type LnbTextProps = {
  title: string;
};
interface PropsType {
  tab: number;
  setTab: (tab: number) => void;
  orderCnt: number;
  shoppingCnt: number;
  reservationCnt: number;
  ticketCnt: number;
}
export const SideNav = ({
  tab,
  setTab,
  orderCnt,
  shoppingCnt,
  reservationCnt,
  ticketCnt,
}: PropsType) => {
  const SidenavIconWrap = ({
    children,
    index,
  }: {
    children: React.ReactNode;
    index: number;
  }) => (
    <div
      className="flex flex-col gap-2 p-4 justify-center items-center cursor-pointer"
      style={{ opacity: tab === index ? "" : "30%" }}
      onClick={() => {
        setTab(index);
      }}
    >
      {children}
    </div>
  );

  const LnbText = ({ title }: LnbTextProps) => (
    <div
      className="text-base"
      style={{ fontFamily: "SUIT-ExtraBold", color: "#2C3832" }}
    >
      {title}
      {title === "주문"
        ? orderCnt
        : title === "티켓"
        ? ticketCnt
        : title === "식당"
        ? reservationCnt
        : title === "쇼핑"
        ? shoppingCnt
        : ""}
    </div>
  );
  return (
    <div
      className="min-w-[128px] flex flex-col h-screen"
      style={{ borderRight: "1px solid #E3E3E3" }}
    >
      <SidenavIconWrap index={0}>
        {<img alt="order" src="/images/sideNav/order.svg" />}
        <LnbText title="주문" />
      </SidenavIconWrap>
      <SidenavIconWrap index={6}>
        {<img alt="order" src="/images/sideNav/ticket.svg" />}
        <LnbText title="티켓" />
      </SidenavIconWrap>
      <SidenavIconWrap index={7}>
        {<img alt="order" src="/images/sideNav/reservation.svg" />}
        <LnbText title="식당" />
      </SidenavIconWrap>
      <SidenavIconWrap index={8}>
        {<img alt="order" src="/images/sideNav/shopping.svg" />}
        <LnbText title="쇼핑" />
      </SidenavIconWrap>

      <SidenavIconWrap index={1}>
        <img alt="order_list" src="/images/sideNav/order_list.svg" />

        <LnbText title="주문내역" />
      </SidenavIconWrap>
      {/* <SidenavIconWrap index={2}>
        <img alt="order_list" src="/images/sideNav/order_list.svg" />

        <LnbText title="정산" />
      </SidenavIconWrap> */}
      <SidenavIconWrap index={3}>
        <img alt="order_list" src="/images/sideNav/order_list.svg" />

        <LnbText title="공지사항" />
      </SidenavIconWrap>
      <SidenavIconWrap index={4}>
        <img alt="order_list" src="/images/sideNav/setting.svg" />

        <LnbText title="설정" />
      </SidenavIconWrap>
      <SidenavIconWrap index={5}>
        <img alt="order_list" src="/images/sideNav/myStore.svg" />

        <LnbText title="내 매장" />
      </SidenavIconWrap>
    </div>
  );
};
