import { useEffect, useState } from "react";
import { MenuPropsType } from "../../types/orderType";
import { AcceptDeliveryModal } from "../modal/acceptDeliveryModal";
import { CancelModal } from "../modal/cancelModal";
import { DeliveryMatchModal } from "../modal/deilveryMatchModal";
import { DetailModal } from "../modal/detailModal";
import Request from "./order/request";
import { useTabStore } from "../../store/tabStore";
import Complete from "./order/complete";

export const Reservation = ({
  setOrderCnt,
  getOrderListApi,
  orderList,
  setDetailModal,
  detailModal,
  setSelectedOrderId,
  selectedOrderId,
  setSelectedItem,
  selectedItem,
  setOnLoading,
  selectedOrderId2,
  setDeliveryMatchModal,
  deliveryMatchModal,
  setAcceptDeliveryModal,
  acceptDeliveryModal,
}: MenuPropsType) => {
  const [cancelModal, setCancelModal] = useState(false);
  const { reservationTab, setReservationTab } = useTabStore();
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("0");

  const Tab = ({ title, index }: { title: string; index: number }) => (
    <div
      className="w-[76px] justify-center items-center flex cursor-pointer"
      style={{
        opacity: String(index) === reservationTab ? "" : "30%",
        borderBottom: "4px solid #2E636E",
      }}
      onClick={() => {
        console.log("index", index);
        setReservationTab(String(index));
      }}
    >
      <div className="text-base" style={{ fontFamily: "SUIT-ExtraBold" }}>
        {title}
      </div>
    </div>
  );
  return (
    <div className="flex flex-col flex-1 pt-4 pl-4 gap-4 pr-4">
      {/* 모달 */}
      {selectedOrderId !== 0 && cancelModal && (
        <CancelModal
          setOnLoading={setOnLoading}
          isOpen={cancelModal}
          setIsOpen={setCancelModal}
          orderId={selectedOrderId}
          orderStatus={selectedOrderStatus}
        ></CancelModal>
      )}
      {detailModal && (
        <DetailModal
          setOnLoading={setOnLoading}
          orderId={selectedOrderId}
          isOpen={detailModal}
          setIsOpen={setDetailModal}
          setDeliveryMatchModal={setDeliveryMatchModal}
          selectedItem={selectedItem}
          selectedOrderId2={selectedOrderId2}
          setOrderId={setSelectedOrderId}
        />
      )}
      <div
        className="flex flex-row gap-4 justify-center items-center "
        style={{ borderBottom: "1px solid #E3E3E3" }}
      >
        <Tab title="요청" index={0} />
        <Tab title="완료" index={1} />
      </div>
      {/* <div className="flex flex-row flex-wrap gap-4">
        {orderList.length > 0 &&
          orderList.map((item, index) => {
            return (
              <div
                key={index}
                className="flex flex-col gap-6 p-4 w-[280px]  justify-between"
                style={{ border: "1px solid #E3E3E3" }}
              >
                <div className="flex flex-col gap-6">
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-col">
                      <div
                        className="text-base"
                        style={{ fontFamily: "SUIT-ExtraBold" }}
                      >
                        {item.storeName}
                      </div>
                      <div
                        className="text-base"
                        style={{ fontFamily: "SUIT-ExtraBold" }}
                      >
                        {item.roomNum}호
                      </div>
                      <div
                        className="text-base"
                        style={{ fontFamily: "SUIT-ExtraBold" }}
                      >
                        # {item.orderId}
                      </div>
                    </div>
                    <div className="flex flex-row gap-4">
                      <div
                        style={{
                          fontFamily: "SUIT-ExtraBold",
                          fontSize: "32px",
                        }}
                      >
                        {item.orderDate.split(" ")[1].slice(0, 5)}
                      </div>
                    </div>
                  </div>
                  {item.orderItemList.map((item2, index2) => {
                    return (
                      <div className="flex flex-col">
                        <div className="flex flex-row justify-between">
                          <div
                            className="text-base"
                            style={{ fontFamily: "SUIT-ExtraBold" }}
                          >
                            {item2.itemName}
                          </div>
                          <div
                            className="text-base"
                            style={{ fontFamily: "SUIT-ExtraBold" }}
                          >
                            1
                          </div>
                        </div>
                        {item2.orderItemOption.length > 0 &&
                          item2.orderItemOption.map((item3, index3) => {
                            return (
                              <div
                                className="flex flex-row pl-2 gap-2"
                                style={{ opacity: "50%" }}
                              >
                                <div className="flex items-start">
                                  <img
                                    src="/images/main/option.svg"
                                    alt="옵션"
                                  />
                                </div>
                                <div
                                  className="text-sm"
                                  style={{ fontFamily: "SUIT-Medium" }}
                                >
                                  {item3.optionName}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
                </div>
                <div className="flex-col flex gap-2">
                  <button
                    className="text-base pt-4 pb-4"
                    style={{
                      backgroundColor: "#FFE4D6",
                      fontFamily: "SUIT-ExtraBold",
                    }}
                    onClick={(e) => {
                      if (item.orderStatus === "00") {
                        setSelectedOrderId(item.orderId);
                        setSelectedItem(item);
                        setDetailModal(true);
                        setSelectedOrderId(item.orderId);
                      } else if (
                        item.orderStatus === "10" ||
                        item.orderStatus === "11"
                      ) {
                        setSelectedOrderId(item.orderId);
                        setDeliveryMatchModal(true);
                      } else if (item.orderStatus === "30") {
                        setAcceptDeliveryModal(true);
                        setSelectedOrderStatus(item.orderStatus);
                        setSelectedOrderId(item.orderId);
                      } else if (item.orderStatus === "32") {
                        setAcceptDeliveryModal(true);
                      } else if (item.orderStatus === "53") {
                        setAcceptDeliveryModal(true);
                      }
                    }}
                  >
                    {item.orderStatus === "00"
                      ? "주문 접수중"
                      : item.orderStatus === "10" || item.orderStatus === "11"
                      ? "배달 대기중"
                      : item.orderStatus === "30"
                      ? item.expectedPickupTime.split(" ")[1] + " 픽업 예정"
                      : item.orderStatus === "32"
                      ? `${item.expectedPickupTime.split(" ")[1]} 픽업 예정`
                      : item.orderStatus === "53" || item.orderStatus === "94"
                      ? "배달중"
                      : ""}
                  </button>
                  {(item.orderStatus === "00" ||
                    item.orderStatus === "30" ||
                    item.orderStatus === "10" ||
                    item.orderStatus === "11") && (
                    <button
                      className="text-base pt-4 pb-4"
                      style={{
                        backgroundColor: "#EC745A",
                        fontFamily: "SUIT-ExtraBold",
                        color: "#FFFFFF",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedOrderId(item.orderId);
                        setSelectedOrderStatus(item.orderStatus);
                        if (item.orderStatus === "00") {
                          setCancelModal(true);
                        } else if (
                          item.orderStatus === "10" ||
                          item.orderStatus === "11"
                        ) {
                        } else if (item.orderStatus === "30") {
                          setAcceptDeliveryModal(true);
                        }
                      }}
                    >
                      {item.orderStatus === "00"
                        ? "주문취소"
                        : item.orderStatus === "10" || item.orderStatus === "11"
                        ? "직접배달"
                        : "배달 요청취소"}
                    </button>
                  )}
                </div>
              </div>
            );
          })}
      </div> */}
      {reservationTab === "0" ? (
        <Request
          orderList={orderList}
          setDetailModal={setDetailModal}
          setSelectedItem={setSelectedItem}
          setSelectedOrderId={setSelectedOrderId}
          setDeliveryMatchModal={setDeliveryMatchModal}
          setAcceptDeliveryModal={setAcceptDeliveryModal}
          setSelectedOrderStatus={setSelectedOrderStatus}
          setCancelModal={setCancelModal}
          setOnLoading={setOnLoading}
        />
      ) : (
        <Complete orderList={orderList} />
      )}
    </div>
  );
};
