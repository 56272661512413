import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

// 상태 및 액션 타입 정의
export type StoreState = {
  storeId: number;
  storePort: string;
  storeBaud: string;
  openTime: string;
  closeTime: string;
  storePhone: string;
  businessNumber: string;
  storeAddress: string;
  storeName: string;
  isOnOff : string;
  voiceCnt : number;
};

export type StoreActions = {
  setStoreId: (storeId: number) => void;
  setStorePort: (storePort: string) => void;
  setStoreBaud: (storeBaud: string) => void;
  setOpenTime: (openTime: string) => void;
  setCloseTime: (closeTime: string) => void;
  setStorePhone: (storePhone: string) => void;
  setBusinessNumber: (businessNumber: string) => void;
  setStoreAddress: (storeAddress: string) => void;
  setStoreName: (storeName: string) => void;
  setIsOnOff: (isOnOff: string) => void;
  setVoiceCnt : (voiceCnt:number)=>void;
};

export type StoreStore = StoreState & StoreActions;

// Zustand 스토어 초기화
export const useStoreStore = create<StoreStore>()(
  persist(
    (set) => ({
      storeId: 0,
      storePort: '',
      storeBaud: '',
      openTime: '',
      closeTime: '',
      storePhone: '',
      businessNumber: '',
      storeAddress: '',
      storeName: '',
      isOnOff:"",
      voiceCnt:1,
      setStoreId: (storeId: number) => set({ storeId }),
      setStorePort: (storePort: string) => set({ storePort }),
      setStoreBaud: (storeBaud: string) => set({ storeBaud }),
      setOpenTime: (openTime: string) => set({ openTime }),
      setCloseTime: (closeTime: string) => set({ closeTime }),
      setStorePhone: (storePhone: string) => set({ storePhone }),
      setBusinessNumber: (businessNumber: string) => set({ businessNumber }),
      setStoreAddress: (storeAddress: string) => set({ storeAddress }),
      setStoreName: (storeName: string) => set({ storeName }),
      setIsOnOff:(isOnOff:string)=>set({isOnOff}),
      setVoiceCnt:(voiceCnt:number)=>set({voiceCnt})
    }),
    {
      name: "food-storage", // 저장소에 저장될 아이템 이름 (고유해야 함)
      storage: createJSONStorage(() => sessionStorage), // sessionStorage 사용
    }
  )
);
