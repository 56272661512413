import { Br, Cut, Line, Printer, Text, Row } from "react-thermal-printer";
import { OrderType } from "../../../types/orderType";

export const ReceiptStore = ({
  order,
  count,
}: {
  order: OrderType;
  count: number;
}) => {
  return (
    <Printer type="epson" width={42} characterSet="korea">
      <Text size={{ width: 2, height: 2 }}>
        {" ".repeat(10)}주문서({order.orderId}){" ".repeat(10)}
      </Text>
      <Text size={{ width: 1, height: 1 }}>
        {order.hotelName ? order.hotelName : ""}
      </Text>
      <Text size={{ width: 1, height: 1 }}>
        {order.hotelAddress ? order.hotelAddress : ""}
      </Text>
      <Text size={{ width: 1, height: 1 }}>
        {order.hotelPhone ? order.hotelPhone : ""}
      </Text>
      <Line />
      <Row left="요청사항 :" right={order.orderMemo} />
      <Row left="전화번호 :" right={order.userPhone} />
      <Line />
      <Row left="메뉴명" center="             수량" right="금액" />
      {order.orderItemList.map((item, index) => {
        // 항목의 최대 길이에 따라 공백을 조정
        const leftPadding = " ".repeat(10 - item.itemName.length); // 메뉴명 길이에 따라 공백 추가
        const centerPadding = " ".repeat(5 - String(item.qty).length); // 수량 길이에 따라 공백 추가
        const rightPadding = " ".repeat(5 - String(item.amount).length); // 금액 길이에 따라 공백 추가

        return (
          <>
            <Row
              key={index}
              left={`${item.itemName}${leftPadding}`} // 메뉴명 뒤에 공백 추가
              center={`${centerPadding}${item.qty}`} // 수량 앞에 공백 추가
              right={`${rightPadding}${item.amount}`} // 금액 앞에 공백 추가
            />
            {item.orderItemOption.map((item2, index2) => {
              return (
                <Row
                  key={index}
                  left={" -" + String(item2.optionName)} // 메뉴명 뒤에 공백 추가
                  right={String(item2.amount) + " "} // 금액 앞에 공백 추가
                />
              );
            })}
          </>
        );
      })}
      <Line />
      <Row
        left="합계" // 메뉴명 뒤에 공백 추가
        center={"                        " + String(count)}
        right={String(order.payAmount)}
      />
      <Line />
      <Text>주문일시 : {order.orderDate}</Text>
      <Br /> <Br /> <Br />
      <Line />
      <Cut />
    </Printer>
  );
};
