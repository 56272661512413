import { useEffect, useState } from "react";
import { getOrder } from "../../@api/service/order/order";
import { formatDate } from "../../utils/func";
import { MenuPropsType, OrderType } from "../../types/orderType";
import { CancelModal } from "../modal/cancelModal";
import { DetailModal } from "../modal/detailModal";
import { DeliveryMatchModal } from "../modal/deilveryMatchModal";
import { AcceptDeliveryModal } from "../modal/acceptDeliveryModal";
import Request from "./order/request";
import Complete from "./order/complete";
import { useTabStore } from "../../store/tabStore";

export const Order = ({
  setOrderCnt,
  getOrderListApi,
  orderList,
  setDetailModal,
  detailModal,
  setSelectedOrderId,
  selectedOrderId,
  setSelectedItem,
  selectedItem,
  setOnLoading,
  selectedOrderId2,
  setDeliveryMatchModal,
  deliveryMatchModal,
  setAcceptDeliveryModal,
  acceptDeliveryModal,
}: MenuPropsType) => {
  const { completeTab, setCompleteTab } = useTabStore();

  const [selectedOrderStatus, setSelectedOrderStatus] = useState("");
  const [cancelModal, setCancelModal] = useState(false);
  //Modal state

  const Tab = ({ title, index }: { title: string; index: number }) => (
    <div
      className="w-[76px] justify-center items-center flex cursor-pointer"
      style={{
        opacity: String(index) === completeTab ? "" : "30%",
        borderBottom: "4px solid #2E636E",
      }}
      onClick={() => {
        console.log("index", index);
        setCompleteTab(String(index));
      }}
    >
      <div className="text-base" style={{ fontFamily: "SUIT-ExtraBold" }}>
        {title}
      </div>
    </div>
  );

  return (
    <div className="flex flex-col flex-1 pt-4 pl-4 gap-4 pr-4">
      {/* 모달 */}
      {selectedOrderId !== 0 && cancelModal && (
        <CancelModal
          setOnLoading={setOnLoading}
          isOpen={cancelModal}
          setIsOpen={setCancelModal}
          orderId={selectedOrderId}
          orderStatus={selectedOrderStatus}
        ></CancelModal>
      )}
      {detailModal && (
        <DetailModal
          setOnLoading={setOnLoading}
          orderId={selectedOrderId}
          isOpen={detailModal}
          setIsOpen={setDetailModal}
          setDeliveryMatchModal={setDeliveryMatchModal}
          selectedItem={selectedItem}
          selectedOrderId2={selectedOrderId2}
          setOrderId={setSelectedOrderId}
        />
      )}
      {selectedOrderId !== 0 && deliveryMatchModal && (
        <DeliveryMatchModal
          setOnLoading={setOnLoading}
          orderId={selectedOrderId}
          isOpen={deliveryMatchModal}
          setIsOpen={setDeliveryMatchModal}
          setAcceptDeliveryModal={setAcceptDeliveryModal}
        />
      )}
      {selectedOrderId !== 0 && acceptDeliveryModal && (
        <AcceptDeliveryModal
          setOnLoading={setOnLoading}
          setIsOpen={setAcceptDeliveryModal}
          orderId={selectedOrderId}
        />
      )}
      <div
        className="flex flex-row gap-4 justify-center items-center "
        style={{ borderBottom: "1px solid #E3E3E3" }}
      >
        <Tab title="요청" index={0} />
        <Tab title="완료" index={1} />
      </div>
      {completeTab === "0" ? (
        <Request
          orderList={orderList}
          setDetailModal={setDetailModal}
          setSelectedItem={setSelectedItem}
          setSelectedOrderId={setSelectedOrderId}
          setDeliveryMatchModal={setDeliveryMatchModal}
          setAcceptDeliveryModal={setAcceptDeliveryModal}
          setSelectedOrderStatus={setSelectedOrderStatus}
          setCancelModal={setCancelModal}
          setOnLoading={setOnLoading}
        />
      ) : (
        <Complete orderList={orderList} />
      )}
    </div>
  );
};
