import React, { useEffect, useRef, useState } from "react";
import { getLogin } from "../../@api/service/auth/auth";
import { redirect, useNavigate } from "react-router-dom";
import { useStoreStore } from "../../store/storeStore";

type NumberBtnProps = {
  item: string;
};

const Login = () => {
  const {
    setStoreId,
    setStorePort,
    setStoreBaud,
    setOpenTime,
    setCloseTime,
    setStorePhone,
    setBusinessNumber,
    setStoreAddress,
    setStoreName,
    setIsOnOff,
  } = useStoreStore();
  const [isFocused1, setIsFocused1] = useState(false);
  const [isFocused2, setIsFocused2] = useState(false);
  const inputRef1 = useRef<HTMLInputElement>(null); // 사업자 번호 입력 필드에 대한 ref
  const inputRef2 = useRef<HTMLInputElement>(null); // 비밀번호 입력 필드에 대한 ref
  const [businessNum, setBusinessNum] = useState("");
  const [pwd, setPwd] = useState("");
  const navigate = useNavigate();

  const NumberBtn = ({ item }: NumberBtnProps) => {
    return (
      <div
        className="flex justify-center items-center rounded-lg cursor-pointer"
        style={{
          width: "120px",
          height: "107px",
          border: "1px solid #D1D1D1",
        }}
        onClick={() => {
          if (isFocused1) {
            if (item === "C") {
              setBusinessNum("");
            } else if (item === "X") {
              setBusinessNum(businessNum.slice(0, businessNum.length - 1));
            } else {
              setBusinessNum(businessNum + item);
            }
            inputRef1.current?.focus(); // 사업자 번호 입력 필드에 포커스 유지
          } else if (isFocused2) {
            if (item === "C") {
              setPwd("");
            } else if (item === "X") {
              setPwd(pwd.slice(0, pwd.length - 1));
            } else {
              setPwd(pwd + item);
            }
            inputRef2.current?.focus(); // 비밀번호 입력 필드에 포커스 유지
          } else {
            inputRef1.current?.focus(); // 아무 입력 필드에도 포커스가 없을 경우 사업자 번호 입력 필드에 포커스
            setBusinessNum(businessNum + item);
          }
        }}
      >
        <div className="text-3xl" style={{ fontFamily: "SUIT-Bold" }}>
          {item}
        </div>
      </div>
    );
  };

  const handleClickOutside = (event: MouseEvent) => {
    const isInput1 = inputRef1.current?.contains(event.target as Node);
    const isInput2 = inputRef2.current?.contains(event.target as Node);
    const isNumberPad = (event.target as HTMLElement).closest(".number-pad");

    if (!isInput1 && !isInput2 && !isNumberPad) {
      setIsFocused1(false);
      setIsFocused2(false);
    }
  };

  const LoginApi = async () => {
    const data = {
      businessNumber: businessNum,
      certNumber: pwd,
    };
    try {
      const result = await getLogin(data);
      console.log(result);
      if (result.message === "성공") {
        console.log("성공");
        localStorage.setItem("accessToken", result.authToken);
        setStoreId(result.data.storeId);
        setBusinessNumber(result.data.businessNumber);
        setOpenTime(result.data.openTime);
        setCloseTime(result.data.closeTime);
        setStorePhone(result.data.storePhone);
        setStoreAddress(result.data.storeAddress);
        setStoreName(result.data.storeName);
        setIsOnOff(result.data.isOnOff);
        setStoreBaud(result.data.deviceIp);
        setStorePort(result.data.devicePort);
        navigate("/main");
      } else {
        alert("로그인을 실패했습니다.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    console.log("auth", localStorage.getItem("accessToken"));
    if (localStorage.getItem("accessToken")) {
      navigate("/main");
    }
  }, []);
  return (
    <div className="w-full h-full flex p-32 items-center">
      <div className="flex w-full" style={{ maxWidth: "1440px", gap: "42px" }}>
        <div className="flex-1 flex-col h-full">
          <img
            src="/images/logo/moaposLogo.svg"
            alt="모아포스"
            style={{ width: "106px", height: "89px" }}
          />
          <div className="flex-col flex gap-4" style={{ marginTop: "116px" }}>
            <div className="flex-col flex gap-2">
              <div className="text-sm" style={{ fontFamily: "SUIT-Medium" }}>
                사업자 번호
              </div>
              <input
                ref={inputRef1} // 사업자 번호 입력 필드에 ref 연결
                className="pb-4 pt-4 pl-2 text-base w-full"
                placeholder="사업자 번호를 입력해주세요."
                value={businessNum}
                onFocus={() => {
                  setIsFocused1(true);
                  setIsFocused2(false);
                }}
                onChange={(e) => {
                  setBusinessNum(e.target.value);
                }}
                style={{
                  border: "1px solid #B6B6B6",
                  fontFamily: "SUIT-Medium",
                }}
              />
            </div>
            <div className="flex-col flex gap-2">
              <div className="text-sm" style={{ fontFamily: "SUIT-Medium" }}>
                비밀번호
              </div>
              <input
                ref={inputRef2} // 비밀번호 입력 필드에 ref 연결
                value={pwd}
                type="password"
                onFocus={() => {
                  setIsFocused2(true);
                  setIsFocused1(false);
                }}
                onChange={(e) => {
                  setPwd(e.target.value);
                }}
                className="pb-4 pt-4 pl-2 text-base w-full"
                placeholder="비밀번호를 입력해주세요."
                style={{
                  border: "1px solid #B6B6B6",
                  fontFamily: "SUIT-Medium",
                }}
              />
            </div>
            <button
              className="text-base"
              style={{
                color: "#FFFFFF",
                backgroundColor:
                  pwd.length > 0 && businessNum.length > 0
                    ? "#EC745A"
                    : "#B6B6B6",
                width: "74px",
                height: "48px",
                fontFamily: "SUIT-Medium",
              }}
              onClick={() => {
                LoginApi();
              }}
            >
              로그인
            </button>
          </div>
        </div>
        <div
          className="number-pad w-[400px] flex flex-col gap-2 justify-end"
          style={{ paddingTop: "59px" }}
        >
          <div className="flex flex-row gap-2">
            <NumberBtn item="1" />
            <NumberBtn item="2" />
            <NumberBtn item="3" />
          </div>
          <div className="flex flex-row gap-2">
            <NumberBtn item="4" />
            <NumberBtn item="5" />
            <NumberBtn item="6" />
          </div>
          <div className="flex flex-row gap-2">
            <NumberBtn item="7" />
            <NumberBtn item="8" />
            <NumberBtn item="9" />
          </div>
          <div className="flex flex-row gap-2">
            <NumberBtn item="C" />
            <NumberBtn item="0" />
            <NumberBtn item="X" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
