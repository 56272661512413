import { create } from "zustand";

// Define the state and actions types

// Combine state and actions into a store type
export type tabState = {
  completeTab : string
  reservationTab : string
  shoppingTab : string
  ticketTab : string
};
export type tabAction = {
setCompleteTab : (tab:string)=> void
setReservationTab : (tab:string)=>void
setShoppingTab : (tab:string)=>void
setTicketTab : (tab:string)=>void
};
 export type TabStore = tabState & tabAction;
// Initialize Zustand store
export const useTabStore = create<TabStore>((set) => ({
completeTab : "",
reservationTab : "",
shoppingTab : "",
ticketTab:"",
  // Function to set the value of reserveAt
  
  setCompleteTab : (completeTab:string) => set({completeTab}),
  setReservationTab:(reservationTab:string)=>set({reservationTab}),
  setShoppingTab:(shoppingTab : string)=>set({shoppingTab}),
  setTicketTab:(ticketTab:string)=>set({ticketTab})
}));