import { useEffect, useState } from "react";
import { useStoreStore } from "../../store/storeStore";

import { Spinner } from "./spinner/spinner";
import {
  getCloseInfo,
  getDeliveryFeeCheck,
  storeOnOff,
} from "../../@api/service/store/store";
import { formatDate } from "../../utils/func";
import axios from "axios";

export const MyStore = () => {
  const {
    storeId,
    storeName,
    storePhone,
    storeAddress,
    businessNumber,
    openTime,
    closeTime,
    isOnOff,
    setIsOnOff,
    storePort,
    storeBaud,
  } = useStoreStore();
  const [deliveryCharge, setDeliveryCharge] = useState([]);
  const [loading, setLoading] = useState(false);
  const Col = ({ title, variable }: { title: string; variable: string }) => (
    <div className="flex flex-col gap-2">
      <div className="text-base" style={{ fontFamily: "SUIT-Medium" }}>
        {title}
      </div>
      <div
        className="py-2 px-4 w-fit"
        style={{ display: "flex", border: "1px solid #000000" }}
      >
        <div className="text-sm" style={{ fontFamily: "SUIT-Medium" }}>
          {variable}
        </div>
      </div>
    </div>
  );

  const storeOnOffApi = async () => {
    try {
      const result = await storeOnOff(storeId, isOnOff);
      console.log("onoff", result);
    } catch (error) {
      console.log(error);
    }
  };
  const getDeliveryFeeCheckApi = async () => {
    try {
      setLoading(true);
      const result = await getDeliveryFeeCheck(storeId);
      setDeliveryCharge(result.data);
      console.log("deliveryCheck", result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getCloseInfoApi = async () => {
    try {
      const result = await getCloseInfo(storeId, formatDate(new Date(), "-"));
      console.log("closeinfo", result);
      printData(result.data, storePort, storeBaud, true, "close");
    } catch (error) {
      console.log(error);
    }
  };
  const printData = async (
    order,
    port = storePort,
    baudRate = storeBaud,
    cut = true,
    type: string
  ) => {
    try {
      const response = await axios.post("http://localhost:5000/print", {
        port: port,
        baudRate: baudRate,
        data: order, // order 정보를 API에 포함
        cut: cut, // 커팅 여부
        type: type,
      });
      // if(response.data ===)
      console.log(response); // 서버 응답 출력
      if (response.status === 200) {
        setIsOnOff("N");
        setTimeout(() => {
          storeOnOffApi();
          localStorage.clear();
          window.location.reload();
        }, 300);
      }
    } catch (error) {
      console.error("Error printing:", error);
    }
  };

  useEffect(() => {
    console.log("isondoff", isOnOff);
  }, [isOnOff]);
  return (
    <div className="flex flex-col pt-8 pl-4 pr-4 w-full gap-8">
      {loading && <Spinner />}
      <div className="flex flex-row justify-between">
        <div className="flex flex-col gap-4">
          <div className="text-2xl" style={{ fontFamily: "SUIT-Bold" }}>
            내 매장
          </div>
        </div>
        <div className="flex flex-row gap-4">
          <button
            className="text-base py-2 px-4 "
            style={{ fontFamily: "SUIT-Bold", border: "1px solid #000000" }}
            onClick={() => {
              window.open("https://admin.stayorder.kr/hotel/detail");
            }}
          >
            스테이오더 관리자
          </button>
          <button
            className="text-base py-2 px-4 "
            style={{ fontFamily: "SUIT-Bold", border: "1px solid #000000" }}
            onClick={() => {
              const isConfirmed = window.confirm("마감하시겠습니까?");
              if (isConfirmed) {
                getCloseInfoApi();
              }
            }}
          >
            마감
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <Col title="가맹점 코드" variable={String(storeId)} />
        <Col title="가맹점 명" variable={storeName} />
        <Col title="가맹점 연락처" variable={storePhone} />
        <Col title="가맹점 주소" variable={storeAddress} />
        <div className="flex flex-row gap-16">
          <div className="flex flex-col gap-4">
            <Col title="가맹점 사업자번호" variable={businessNumber} />
            <div className="flex flex-col gap-2">
              <div className="text-base" style={{ fontFamily: "SUIT-Medium" }}>
                운영시간
              </div>
              <div className="flex flex-row gap-4 items-center">
                <div
                  className="py-2 px-4 w-fit"
                  style={{ display: "flex", border: "1px solid #000000" }}
                >
                  <div
                    className="text-sm"
                    style={{ fontFamily: "SUIT-Medium" }}
                  >
                    {openTime}
                  </div>
                </div>
                <div>~</div>
                <div
                  className="py-2 px-4 w-fit"
                  style={{ display: "flex", border: "1px solid #000000" }}
                >
                  <div
                    className="text-sm"
                    style={{ fontFamily: "SUIT-Medium" }}
                  >
                    {closeTime}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="text-base" style={{ fontFamily: "SUIT-Medium" }}>
              운영 ON/OFF
            </div>
            <div
              className="flex flex-col gap-4 py-4 px-8"
              style={{ border: "1px solid #E2E2E2" }}
            >
              <div className="flex flex-row gap-2 justify-center">
                <div className="flex flex-row gap-1 center-y">
                  <input
                    type="radio"
                    value="operation"
                    checked={isOnOff === "Y"}
                    onChange={() => {
                      setIsOnOff("Y");
                    }}
                  />
                  <div className="text-xs" style={{ fontFamily: "SUIT-Bold" }}>
                    ON
                  </div>
                </div>
                <div className="flex flex-row gap-1">
                  <input
                    type="radio"
                    value="close"
                    checked={isOnOff === "N"}
                    onChange={() => {
                      setIsOnOff("N");
                    }}
                  />
                  <div className="text-xs" style={{ fontFamily: "SUIT-Bold" }}>
                    OFF
                  </div>
                </div>
              </div>
              <button
                className="text-base py-2 px-4 "
                style={{ fontFamily: "SUIT-Bold", border: "1px solid #000000" }}
                onClick={() => {
                  storeOnOffApi();
                }}
              >
                설정
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="text-base" style={{ fontFamily: "SUIT-Medium" }}>
            배달업체
          </div>
          <button
            className="w-fit flex text-center text-sm p-4"
            onClick={() => {
              getDeliveryFeeCheckApi();
            }}
            style={{ border: "1px solid #000000", fontFamily: "SUIT-Medium" }}
          >
            가맹점 배달비 충전조회
          </button>
          <div
            className="w-3/4 h-20 flex p-2"
            style={{ border: "1px solid #000000" }}
          >
            <div className="flex flex-col gap-[2px] w-full">
              {deliveryCharge.map((item, index) => {
                return (
                  <div className="flex-row flex gap-10">
                    <div
                      className="flex w-[250px] text-base"
                      style={{ fontFamily: "SUIT-Medium" }}
                    >
                      {item.delivery_name}
                    </div>
                    <div className="flex w-[100px]">
                      <div
                        className="w-full text-right text-base"
                        style={{ fontFamily: "SUIT-Medium" }}
                      >
                        {Number(item.store_cash).toLocaleString()}원
                      </div>
                    </div>
                    <div
                      className="flex w-[100px] text-base"
                      style={{ fontFamily: "SUIT-Medium" }}
                    >
                      {item.v_bank_name}
                    </div>
                    <div
                      className="flex flex-1 text-base"
                      style={{ fontFamily: "SUIT-Medium" }}
                    >
                      {item.v_bank_account}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
