import { request } from "../../request"

export const storeOnOff = async (storeId:number, isOnOff:string) => {
    try {
        const result = await request.post(`posStore/updStoreOnOff?storeId=${storeId}&isOnOff=${isOnOff}`)
        return result.data
    } catch (error) {
        console.log(error)
    }
}

export const getDeliveryFeeCheck = async (storeId:number) => {
    try {
        const result = await request.get(`posStore/getDeliveryFeeCharge?storeId=${storeId}`)
        return result.data
    } catch (error) {
        console.log(error)
    }
}

export const getCloseInfo = async (storeId:number, selDate:string) => {
    try {
        const result = await request.get(`deadline/getDeadLine?storeId=${storeId}&selDate=${selDate}`)
        return result.data
    } catch (error) {
        console.log(error)
    }
}