export const HalfBtnClose = ({
  setIsOpen,
}: {
  setIsOpen: (props: boolean) => void;
}) => {
  return (
    <button
      onClick={() => {
        setIsOpen(false);
      }}
      className="flex items-center justify-center text-2xl p-6 w-1/4"
      style={{ fontFamily: "SUIT-Bold", border: "1px solid #2C3832" }}
    >
      닫기
    </button>
  );
};

export const HalfBtn = ({
  onClick,
  text,
}: {
  onClick: () => void;
  text: string;
}) => {
  return (
    <button
      className="pt-[22px] pb-[22px] justify-center items-center w-1/2 text-2xl text-white"
      style={{ backgroundColor: "#EC745A", fontFamily: "SUIT-Bold" }}
      onClick={() => {
        onClick();
      }}
    >
      {text}
    </button>
  );
};
