import { request } from "../../request"

interface LoginType {
    businessNumber : string;
    certNumber:string
}

export const getLogin = async (data:LoginType) =>{
    try {
        const result = await request.post(`login/doLoginAjax2`, data)
        return result.data
    } catch (error) {
        console.log(error)
       if (
      error.response &&
      error.response.data &&
      error.response.data.message==="603"
    ) {
      // 로컬 스토리지에서 토큰 제거 (선택 사항)
    //   localStorage.removeItem("accessToken");
    //   alert("토큰이 만료되었습니다. 로그인을 다시 해주세요.")
    //   // 로그인 페이지로 리디렉트
    //   window.location.href = "/login"; // 또는 다른 리다이렉트 방법
    }  
    }
}
export const getStoreInfo = async () => {
    try {
        const result = await request.get(`order/getAuth`)
        return result.data
    } catch (error) {
        console.log(error)
    }
}