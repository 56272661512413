import { useEffect, useState } from "react";
import { Modal } from "../../component/Modal";
import { getOrderStatus } from "../../@api/service/order/order";
import { cancelDelivery } from "../../@api/service/delivery/delivery";
import { useStoreStore } from "../../store/storeStore";

export const AcceptDeliveryModal = ({
  setIsOpen,
  orderId,
  setOnLoading,
}: {
  setIsOpen: (isOpen: boolean) => void;
  orderId: number;
  setOnLoading: (onLoading: boolean) => void;
}) => {
  const { storeId, setStoreId } = useStoreStore();
  const [liveOrderStatus, setLiveOrderStatus] = useState("0"); // 모달에서도 orderstatus가 계속 변해야해서 props값으로 고정으로 받으면 안됌
  const [remainingTime, setRemainingTime] = useState(30);
  const getOrderStatusApi = async () => {
    try {
      const result = await getOrderStatus(storeId, orderId);
      setLiveOrderStatus(result.data.orderStatus);

      console.log("status", result);
    } catch (error) {
      console.log(error);
    }
  };

  const cancelDeliveryApi = async () => {
    setOnLoading(true);
    try {
      const result = await cancelDelivery(orderId);
      console.log("cancel", result);
      setIsOpen(false);
      setOnLoading(false);
    } catch (error) {
      console.log(error);
      setOnLoading(false);
    }
  };

  useEffect(() => {
    getOrderStatusApi();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime((prev) => {
        if (prev === 1) {
          setIsOpen(false); // 30초 후 모달 닫기
          clearInterval(intervalId);
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId); // 컴포넌트 언마운트 시 정리
  }, []);
  useEffect(() => {
    if (liveOrderStatus === "94") {
      setTimeout(() => {
        setIsOpen(false);
      }, 2000);
    }
  }, [liveOrderStatus]);
  const ChangeText = () => {
    if (liveOrderStatus === "31" || liveOrderStatus === "30") {
      return (
        <div>
          배달이 접수되었습니다! <br />
          기사님을 곧 배정할게요!
        </div>
      );
    } else if (liveOrderStatus === "32") {
      return (
        <div>
          배달 기사님이 배정되었습니다. <br />
          배달요청을 취소하실 수 없습니다!
        </div>
      );
    } else if (liveOrderStatus === "53") {
      return (
        <div>
          배달 기사님이 배정되었습니다. <br />
          배달요청을 취소하실 수 없습니다!
        </div>
      );
    } else if (liveOrderStatus === "94") {
      return (
        <div>
          소중한 고객님의 배송 장소로 <br />
          안전하게 배달이 완료되었습니다!
        </div>
      );
    }

    // 조건이 모두 맞지 않을 경우 null 반환
    return null;
  };

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    const fetchOrders = async () => {
      await getOrderStatusApi();
      timeoutId = setTimeout(fetchOrders, 5000); // 요청이 완료된 후 10초 후에 다시 호출
    };

    fetchOrders(); // 최초 호출

    return () => {
      clearTimeout(timeoutId); // 컴포넌트 언마운트 시 타임아웃 정리
    };
  }, []);
  return (
    <Modal setIsOpen={setIsOpen}>
      <div className="flex flex-col gap-8">
        <div className="flex justify-end pr-4 ">
          <img
            src="/images/icon/close.svg"
            className="w-6 h-6 cursor-pointer"
            alt="close"
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </div>
        <div className="text-end pr-4">
          <p className="text-base" style={{ fontFamily: "SUIT-Medium" }}>
            자동 닫힘: {remainingTime}초 전
          </p>
        </div>
        <div
          className="text-center text-[32px] leading-[41px]"
          style={{ fontFamily: "SUIT-Bold" }}
        >
          {ChangeText()}
        </div>
        <div className="flex flex-row items-center justify-center">
          <div className="flex p-2 bg-[#2E636E] items-center justify-center">
            <div
              className="text-base text-white"
              style={{ fontFamily: "SUIT-ExtraBold" }}
            >
              요청접수
            </div>
          </div>
          <div
            className="w-[50px]"
            style={{
              borderBottom: "3px dotted #2E636E",
            }}
          />
          <div
            className="flex p-2 items-center justify-center"
            style={{
              backgroundColor:
                Number(liveOrderStatus) >= 32 ? "#2E636E" : "#B1CED44D",
            }}
          >
            <div
              className="text-base "
              style={{
                fontFamily: "SUIT-ExtraBold",
                opacity: Number(liveOrderStatus) >= 32 ? "" : "30%",
                color: Number(liveOrderStatus) >= 32 ? "white" : "black",
              }}
            >
              기사배정
            </div>
          </div>
          <div
            className="w-[50px]"
            style={{
              borderBottom: "3px dotted #2E636E",
            }}
          />
          <div
            className="flex p-2 items-center justify-center"
            style={{
              backgroundColor:
                Number(liveOrderStatus) >= 53 ? "#2E636E" : "#B1CED44D",
            }}
          >
            <div
              className="text-base text-white"
              style={{
                fontFamily: "SUIT-ExtraBold",
                opacity: Number(liveOrderStatus) >= 53 ? "" : "30%",
                color: Number(liveOrderStatus) >= 53 ? "white" : "black",
              }}
            >
              기사픽업
            </div>
          </div>
          <div
            className="w-[50px]"
            style={{
              borderBottom: "3px dotted #2E636E",
            }}
          />
          <div
            className="flex p-2 items-center justify-center"
            style={{
              backgroundColor:
                Number(liveOrderStatus) >= 94 ? "#2E636E" : "#B1CED44D",
            }}
          >
            <div
              className="text-base text-white"
              style={{
                fontFamily: "SUIT-ExtraBold",
                opacity: Number(liveOrderStatus) >= 94 ? "" : "30%",
                color: Number(liveOrderStatus) >= 94 ? "white" : "black",
              }}
            >
              배달완료
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-4 pl-8 pr-8">
          <button
            className="pt-[22px] pb-[22px] justify-center items-center w-1/2 text-2xl"
            style={{
              border:
                liveOrderStatus === "30"
                  ? "1px solid #2C3832"
                  : "1px solid #B6B6B6",
              fontFamily: "SUIT-Bold",
              color: liveOrderStatus === "30" ? "#2C3832" : "#B6B6B6",
            }}
            onClick={() => {
              if (liveOrderStatus === "30") {
                cancelDeliveryApi();
              }
            }}
          >
            배달 요청 취소
          </button>
          <button
            className="pt-[22px] pb-[22px] justify-center items-center w-1/2 text-2xl text-white"
            style={{ backgroundColor: "#EC745A", fontFamily: "SUIT-Bold" }}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            닫기
          </button>
        </div>
      </div>
    </Modal>
  );
};
