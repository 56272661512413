interface SerialPort {
  open(options: { baudRate: number }): Promise<void>;
  close(): Promise<void>;
  readable: ReadableStream<Uint8Array> | null;
  writable: WritableStream<Uint8Array> | null;
  // 필요한 추가 메서드 및 속성 정의
}

interface Navigator {
  serial: {
    requestPort(): Promise<SerialPort>;
    getPorts(): Promise<SerialPort[]>;
  };
}


import { redirect } from "react-router-dom";
import { render } from "react-thermal-printer";
import { ReceiptStore } from "../page/component/receipt/ReceiptStore";
import { ReceiptCustomer } from "../page/component/receipt/ReceiptCustomer";
import { OrderType } from "../types/orderType";

export const formatDate = (date: any, deli?:string) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 +1
  const day = String(date.getDate()).padStart(2, "0");

  if(deli) {
     return `${year}${deli}${month}${deli}${day}`;
  } else {
    return `${year}/${month}/${day}`;
  }
};
export function checkAuthLoader() {
  const token = localStorage.getItem("accessToken");
  console.log("token", token);
  if (!token) {
    return redirect("/login"); // 토큰이 없으면 로그인 페이지로 리디렉션
  }
}

export const useAuthenticate = (): boolean => {
  const isUserLoggedIn = localStorage.getItem("accessToken") ? true : false;

  return isUserLoggedIn;
};

export const onClickPrintHandler = async (
  category: string,
  order: OrderType,
  count: number,
  port: SerialPort
) => {
  console.log("onclickhandler");

  const mergeUint8Arrays = (arrays) => {
    const totalLength = arrays.reduce((acc, curr) => acc + curr.length, 0);
    const result = new Uint8Array(totalLength);
    let offset = 0;

    arrays.forEach((array) => {
      result.set(array, offset);
      offset += array.length;
    });

    return result;
  };

  let data;
  try {
    console.log("receipt");
    if (category === "store") {
      console.log("store");
      data = await render(ReceiptStore({ order, count }));
    } else {
      console.log("customer");
      data = await render(ReceiptCustomer({ order, count }));
    }

    // 빈 줄 추가
    const paddingLines = 5;
    const padding = new Uint8Array(paddingLines).fill(0x0a);

    // 데이터 병합
    data = mergeUint8Arrays([data, padding]);

    const writer = port.writable?.getWriter();

    if (writer) {
      // 영수증 데이터 전송
      await writer.write(data);

      // 약간의 지연 시간을 추가
      await new Promise((resolve) => setTimeout(resolve, 300)); // 100ms 대기

      // 커팅 명령어 전송
      const cutCommand = new Uint8Array([0x1d, 0x69]); // 커팅 명령어
      await writer.write(cutCommand);

      writer.releaseLock();
    }
  } catch (error) {
    console.error("Failed to print:", error);
  }
};