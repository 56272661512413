import { ReactNode, useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // 기본 스타일 가져오기
import { Table } from "./orderlist/Table";
import { Receipt } from "./orderlist/Recipt";
import { formatDate } from "../../utils/func";
import { getOrderBarcode, getOrderList } from "../../@api/service/order/order";
import { OrderType } from "../../types/orderType";
import { useStoreStore } from "../../store/storeStore";
import { render } from "react-thermal-printer";
import { ReceiptStore } from "./receipt/ReceiptStore";
export const OrderList = ({
  setDetailModal,
  setSelectedItem,
}: {
  setDetailModal: (detailModal: boolean) => void;
  setSelectedItem: (selectedItem: OrderType) => void;
}) => {
  const [itemIndex, setItemIndex] = useState(0);
  const [date1, setDate1] = useState(formatDate(new Date()));
  const [date2, setDate2] = useState(formatDate(new Date()));
  const [openCal1, setOpenCal1] = useState(false);
  const [openCal2, setOpenCal2] = useState(false);
  const [orderList, setOrderList] = useState<OrderType[]>([]);
  const { storeId, setStoreId } = useStoreStore();
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
    null
  ); // 타이머 상태
  const barcodeRef = useRef(""); // 현재 입력 중인 바코드 문자열을 저장하는 Ref
  const [barcode, setBarcode] = useState(""); // 스캔된 바코드를 저장할 상태

  const getOrderListApi = async () => {
    try {
      const result = await getOrderList(storeId, date1, date2, "Y");
      console.log("orderlist", result.data);
      setOrderList(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getOrderBarcodeApi = async (orderId: string) => {
    const now = new Date();
    const currentHour = String(now.getHours()).padStart(2, "0"); // 16
    const currentMinute = String(now.getMinutes()).padStart(2, "0"); // 22
    const currentTime = currentHour + currentMinute; // '1622'

    try {
      const result = await getOrderBarcode(Number(orderId));
      console.log("barcode", result.data);
      const temp = new Date().toISOString().split("T")[0];
      if (!result.data.reservationList.includes(temp))
        return alert("예약일이 아닙니다.");
      // 예약시간 값 체크
      const reservTime = result.data.reservTime;

      // 예약시간이 제대로 받아졌는지 확인
      if (!reservTime) {
        throw new Error("예약시간이 없습니다.");
      }

      // 예약시간을 시와 분으로 분리하여 비교하기 위한 변수로 변환
      const reservationHour = parseInt(reservTime.slice(0, 2), 10);
      const reservationMinute = parseInt(reservTime.slice(2, 4), 10);

      // 예약시간 값이 유효한지 확인
      if (isNaN(reservationHour) || isNaN(reservationMinute)) {
        throw new Error("유효하지 않은 예약시간입니다.");
      }

      // 예약시간을 Date 객체로 변환
      const reservationDate = new Date();
      reservationDate.setHours(reservationHour, reservationMinute, 0, 0);
      console.log("reservationdate", reservationDate);
      // 현재 시간과 예약 시간의 차이를 계산 (분 단위)
      const diffInMinutes =
        (now.getTime() - reservationDate.getTime()) / 1000 / 60;
      console.log("diffmin", diffInMinutes);
      // 예약시간과 현재시간 비교
      if (diffInMinutes < -30) {
        alert(
          `예약시간 보다 빠릅니다. 예약시간은 ${
            reservTime.slice(0, 2) + ":" + reservTime.slice(2, 4)
          }입니다.`
        );
      } else if (diffInMinutes >= -30 && diffInMinutes < 0) {
        const message = `예약된 시간보다 ${Math.round(
          Math.abs(diffInMinutes)
        )}분 빠릅니다. 주문을 진행하시겠습니까?`;
        const isConfirmed = window.confirm(message);

        if (isConfirmed) {
          setSelectedItem(result.data);
        }
      } else if (diffInMinutes >= 0 && diffInMinutes <= 30) {
        setSelectedItem(result.data);
      } else if (diffInMinutes > 30) {
        const message = `예약된 시간보다 ${Math.round(
          diffInMinutes
        )}분 늦었습니다. 주문을 진행하시겠습니까?`;
        const isConfirmed = window.confirm(message);

        if (isConfirmed) {
          setSelectedItem(result.data);
        }
      }

      console.log("barcode", result);
    } catch (error) {
      alert("유효하지 않은 바코드입니다.");
      console.error("에러 발생:", error);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Backspace나 Input 태그에서 발생하는 이벤트는 무시
      if (
        event.key === "Backspace" ||
        (event.target as HTMLElement).tagName === "INPUT"
      ) {
        return;
      }
      console.log("eventkey", event.key);
      if (/^F\d+$/.test(event.key)) {
        return;
      }
      // Enter 키만 처리하고 나머지는 무시
      if (event.key === "Enter") {
        setBarcode(barcodeRef.current);
        getOrderBarcodeApi(barcodeRef.current);
        barcodeRef.current = "";
      } else if (/[0-9]/.test(event.key)) {
        // 숫자만 Ref에 누적
        console.log("cur", barcodeRef);
        barcodeRef.current += event.key;
      }

      // 타이머 초기화
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      // 일정 시간 후 입력이 멈추면 바코드 처리
      const newTimeout = setTimeout(() => {
        if (barcodeRef.current) {
          setBarcode(barcodeRef.current);
          getOrderBarcodeApi(barcodeRef.current);
          barcodeRef.current = "";
        }
      }, 500);

      setTypingTimeout(newTimeout);
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [typingTimeout]);
  useEffect(() => {
    getOrderListApi();
  }, []);
  return (
    <div
      className="flex flex-col pl-4 pt-8 gap-6 w-full"
      style={{ overflow: "hidden" }}
    >
      <div className="flex flex-row justify-between w-1/2">
        <div className="text-2xl" style={{ fontFamily: "SUIT-Bold" }}>
          주문내역
        </div>
        <div className="flex flex-row gap-2  items-center">
          <div
            className="text-base text-center"
            style={{ fontFamily: "SUIT-Medium" }}
          >
            바코드
          </div>
          <input
            style={{ border: "1px solid #000000" }}
            value={barcode}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter") {
                getOrderBarcodeApi(e.currentTarget.value);
              }
            }}
            onChange={(e) => {
              setBarcode(e.target.value);
            }}
          />
        </div>
      </div>
      {/* header */}
      <div className="flex flex-row gap-2 items-center">
        {openCal1 && (
          <Calendar
            onChange={(e) => {
              setOpenCal1(false);
              setDate1(formatDate(e));
            }}
            className="absolute z-10 top-[120px]"
          />
        )}
        {openCal2 && (
          <Calendar
            onChange={(e) => {
              setOpenCal2(false);
              setDate2(formatDate(e));
            }}
            className="absolute z-10 top-[120px]"
          />
        )}
        <div
          className="flex flex-row gap-2 pt-3 pr-4 pb-3 pl-4 items-center justify-center relative"
          style={{ border: "1px solid #E3E3E3" }}
          onClick={() => {
            setOpenCal1(true);
          }}
        >
          <img src="/images/icon/calendar.svg" alt="calendar" />
          <div className="text-sm" style={{ fontFamily: "SUIT-Bold" }}>
            {date1}
          </div>
        </div>
        <div>-</div>
        <div
          className="flex flex-row gap-2 pt-3 pr-4 pb-3 pl-4 items-center justify-center"
          style={{ border: "1px solid #E3E3E3" }}
          onClick={() => {
            setOpenCal2(true);
          }}
        >
          <img src="/images/icon/calendar.svg" alt="calendar" />
          <div className="text-sm" style={{ fontFamily: "SUIT-Bold" }}>
            {date2}
          </div>
        </div>
        <button
          className=" pt-3 pr-4 pb-3 pl-4 text-sm"
          style={{ border: "1px solid #2C3832", fontFamily: "SUIT-Bold" }}
          onClick={() => {
            getOrderListApi();
          }}
        >
          검색
        </button>
      </div>
      {/* body */}
      <div className="flex flex-row gap-8 w-full pr-4">
        {/* 표 */}
        <Table
          orderList={orderList}
          setItemIndex={setItemIndex}
          itemIndex={itemIndex}
        />
        {/* 영수증 */}
        <Receipt
          itemIndex={itemIndex}
          order={orderList[itemIndex]}
          setItemIndex={setItemIndex}
          length={orderList.length}
          getOrderListApi={getOrderListApi}
        />
      </div>
    </div>
  );
};
