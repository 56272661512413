import { useEffect, useState } from "react";
import { ApproveOrder } from "../../@api/service/order/order";
import { Modal } from "../../component/Modal";
import { OrderType } from "../../types/orderType";
import { render } from "react-thermal-printer";
import { ReceiptStore } from "../component/receipt/ReceiptStore";
import { ReceiptCustomer } from "../component/receipt/ReceiptCustomer";
import { onClickPrintHandler } from "../../utils/func";
import axios from "axios";
import { useStoreStore } from "../../store/storeStore";
export const DetailModal = ({
  isOpen,
  setIsOpen,
  orderId,
  selectedItem,
  setDeliveryMatchModal,
  setOnLoading,
  setOrderId,
  selectedOrderId2,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  orderId: number;
  selectedItem: OrderType;
  setDeliveryMatchModal: (deliveryMatchModal: boolean) => void;
  setOnLoading: (onLoading: boolean) => void;
  setOrderId: (orderId: number) => void;
  selectedOrderId2: number;
}) => {
  console.log("orderid", orderId);
  const [time, setTime] = useState(30);
  const { storeBaud, storePort } = useStoreStore();
  const [count, setCount] = useState(0);
  const printData = async (
    order,
    port = storePort,
    baudRate = storeBaud,
    cut = true,
    type: string
  ) => {
    try {
      const response = await axios.post("http://localhost:5000/print", {
        port: port,
        baudRate: baudRate,
        data: order, // order 정보를 API에 포함
        cut: cut, // 커팅 여부
        type: type,
      });
      console.log(response.data); // 서버 응답 출력
    } catch (error) {
      console.error("Error printing:", error);
    }
  };
  const ApproveApi = async () => {
    setOnLoading(true);
    try {
      await printData(selectedItem, storePort, storeBaud, true, "store");
    } catch (error) {
      console.log("Error in store print:", error);
    }

    try {
      await printData(selectedItem, storePort, storeBaud, true, "customer");
    } catch (error) {
      console.log("Error in customer print:", error);
    }
    const formatDate = (date: any) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${year}.${month}.${day} ${hours}:${minutes}`;
    };

    const now = formatDate(new Date());
    try {
      const result = await ApproveOrder(
        selectedItem.orderId,
        selectedItem.orderType === "조식"
          ? "91"
          : selectedItem.orderType === "룸서비스"
          ? "90"
          : selectedItem.orderType === "식당"
          ? "92"
          : selectedItem.orderType === "티켓"
          ? "93"
          : selectedItem.orderType === "쇼핑"
          ? "95"
          : "10",
        now,
        "Y"
      );
      console.log("approve", result);

      //리액트에서 프린트하는 로직
      // if ("serial" in navigator) {
      //   let port;

      //   // 이전에 선택한 포트를 로컬 스토리지에서 가져오기
      //   const savedPortInfo = localStorage.getItem("selectedPort");
      //   if (savedPortInfo) {
      //     const savedPort = JSON.parse(savedPortInfo);
      //     const ports = await navigator.serial.getPorts();
      //     port = ports.find(
      //       (p) =>
      //         p.getInfo().usbVendorId === savedPort.usbVendorId &&
      //         p.getInfo().usbProductId === savedPort.usbProductId
      //     );
      //   }

      //   if (!port) {
      //     // 사용자가 포트를 선택하도록 요청
      //     port = await navigator.serial.requestPort();
      //     console.log("Port selected:", port);

      //     // 선택한 포트를 로컬 스토리지에 저장
      //     const portInfo = port.getInfo();
      //     localStorage.setItem(
      //       "selectedPort",
      //       JSON.stringify({
      //         usbVendorId: portInfo.usbVendorId,
      //         usbProductId: portInfo.usbProductId,
      //       })
      //     );
      //   } else {
      //     console.log("Using saved port:", port);
      //   }

      //   await port.open({ baudRate: 9600 });

      //   // 첫 번째 프린트 작업 (store 용)
      //   await onClickPrintHandler("store", selectedItem, count, port);

      //   // 두 번째 프린트 작업 (customer 용)
      //   await onClickPrintHandler("customer", selectedItem, count, port);

      //   // 포트 닫기
      //   await port.close();
      // }

      if (
        // selectedItem.orderType !== "조식" &&
        // selectedItem.orderType !== "룸서비스" &&
        // selectedItem.orderType !== "식당" &&
        // selectedItem.orderType !== "쇼핑" &&
        // selectedItem.orderType !== "티켓"
        selectedItem.orderType === "배달"
      ) {
        setDeliveryMatchModal(true);
        setOnLoading(false);
        setIsOpen(false);
      } else {
        setIsOpen(false);
        setOnLoading(false); // 로딩 상태 종료
      }
    } catch (error) {
      setOnLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    // 새로 주문창이 뜨고 거기서 주문승인을 누르면 orderid가 이전 orderid로 변경되지 않아서 이렇게 수정
    // 이전 창에서 진행하면 selectedorderid를 변경하는 순간 상세 모달이 같이 튀어나와서(useeffect의 디펜던시가 그걸로 되어있어서) 여기서 수정
    if (selectedOrderId2 !== 0) {
      setOrderId(selectedOrderId2);
    }
    const totalQty = selectedItem.orderItemList.reduce(
      (acc, item) => acc + item.qty,
      0
    );
    setCount(totalQty);
  }, [selectedOrderId2]);

  return (
    <Modal setIsOpen={setIsOpen}>
      <div className="flex justify-end pr-4">
        <img
          className="cursor-pointer"
          src="/images/icon/close.svg"
          alt="close"
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </div>
      {/* 주소 */}
      <div className="flex flex-col p-8 gap-6">
        <div className="flex-row flex justify-between">
          <div className="flex flex-col gap-2">
            <div className="text-[32px]" style={{ fontFamily: "SUIT-Bold" }}>
              {selectedItem.hotelName} {selectedItem.roomNum}호
            </div>
            <div className="text-lg" style={{ fontFamily: "SUIT-Bold" }}>
              {selectedItem.hotelAddress}
            </div>
          </div>
          <div
            className="p-2 justify-center flex items-center w-fit h-fit"
            style={{ backgroundColor: "#B1CED44D" }}
          >
            <div
              className="text-base opacity-30"
              style={{ color: "#000000", fontFamily: "SUIT-ExtraBold" }}
            >
              스테이오더
            </div>
          </div>
        </div>
        {/* 요청사항 */}
        <div className="p-4 bg-[#EC5A5A0F] flex rounded-lg">
          <div
            className="text-base text-[#EC5A5A]"
            style={{ fontFamily: "SUIT-Medium" }}
          >
            {selectedItem.orderMemo || ""}
          </div>
        </div>
        {/* 주문 내역 */}
        <div
          className="flex flex-col gap-4 pb-6"
          style={{ borderBottom: "1px solid #E3E3E3" }}
        >
          {selectedItem.orderItemList.map((item, index) => {
            return (
              <div className="flex flex-col">
                <div className="flex flex-row justify-between items-center">
                  <div
                    className="text-base"
                    style={{ fontFamily: "SUIT-ExtraBold" }}
                  >
                    {item.itemName}
                  </div>
                  <div className="flex flex-row gap-[40px] items-center">
                    <div
                      className="text-base"
                      style={{ fontFamily: "SUIT-ExtraBold" }}
                    >
                      {item.qty}
                    </div>
                    <div
                      className="text-base color-[#000000]"
                      style={{ fontFamily: "SUIT-Medium" }}
                    >
                      {item.amount.toLocaleString()}
                    </div>
                  </div>
                </div>

                {item.orderItemOption.map((item2, index) => {
                  return (
                    <div key={index} className="flex flex-row justify-between">
                      <div
                        className="flex flex-row pl-2 gap-2"
                        style={{ opacity: "50%" }}
                      >
                        <div className="flex items-start">
                          <img src="/images/main/option.svg" alt="옵션" />
                        </div>
                        <div
                          className="text-sm"
                          style={{ fontFamily: "SUIT-Medium" }}
                        >
                          {item2.optionName}
                        </div>
                      </div>
                      <div className="flex flex-row gap-[40px]">
                        <div style={{ opacity: "50%" }}>{item2.amount}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        {/* 주문자 정보 */}
        <div
          className="flex flex-col gap-4 pb-6"
          style={{ borderBottom: "1px solid #E3E3E3" }}
        >
          <div className="flex flex-row justify-between">
            <div
              className="text-base opacity-50"
              style={{ fontFamily: "SUIT-Medium" }}
            >
              주문시각
            </div>
            <div style={{ fontFamily: "SUIT-Medium" }} className="text-base">
              {selectedItem.orderDate}
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div
              className="text-base opacity-50"
              style={{ fontFamily: "SUIT-Medium" }}
            >
              주문자명
            </div>
            <div style={{ fontFamily: "SUIT-Medium" }} className="text-base">
              {selectedItem.userName}
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div
              className="text-base opacity-50"
              style={{ fontFamily: "SUIT-Medium" }}
            >
              주문자 휴대폰번호
            </div>
            <div style={{ fontFamily: "SUIT-Medium" }} className="text-base">
              {selectedItem.userPhone}
            </div>
          </div>
          {selectedItem.orderType === "식당" ||
          selectedItem.orderType === "조식" ? (
            <div className="flex flex-row justify-between">
              <div
                className="text-base opacity-50"
                style={{ fontFamily: "SUIT-Medium" }}
              >
                예약 일자
              </div>
              <div style={{ fontFamily: "SUIT-Medium" }} className="text-base">
                {selectedItem.reservationList}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        {/* 버튼 */}
        <div className="flex flex-row gap-4">
          <button
            onClick={() => {
              setIsOpen(false);
            }}
            className="flex items-center justify-center text-2xl p-6 w-1/4"
            style={{ fontFamily: "SUIT-Bold", border: "1px solid #2C3832" }}
          >
            닫기
          </button>
          <div
            className="flex flex-row justify-between w-1/2 p-6"
            style={{ border: "1px solid #EC745A" }}
          >
            <div
              style={{ fontFamily: "SUIT-Bold" }}
              className="text-2xl cursor-pointer text-[#EC745A]"
              onClick={() => {
                setTime(time - 5);
              }}
            >
              -
            </div>
            <div
              style={{ fontFamily: "SUIT-Bold" }}
              className="text-2xl text-[#EC745A]"
            >
              {time}분
            </div>
            <div
              style={{ fontFamily: "SUIT-Bold" }}
              className="text-2xl cursor-pointer text-[#EC745A]"
              onClick={() => {
                setTime(time + 5);
              }}
            >
              +
            </div>
          </div>
          <button
            className="flex items-center justify-center text-2xl p-6 w-1/4 bg-[#EC745A] text-white"
            style={{ fontFamily: "SUIT-Bold" }}
            onClick={() => {
              ApproveApi();
            }}
          >
            주문승인
          </button>
        </div>
      </div>
    </Modal>
  );
};
