import { ReactNode, useEffect, useState } from "react";
import { CancelOrder } from "../../@api/service/order/order";
import { Modal } from "../../component/Modal";
import { HalfBtn, HalfBtnClose } from "../component/button/button";

export const CancelModal = ({
  isOpen,
  setIsOpen,
  orderId,
  orderStatus,
  setOnLoading,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  orderId: number;
  orderStatus: string;
  setOnLoading: (onLoading: boolean) => void;
}) => {
  const [reason, setReason] = useState<number>();
  const Button = ({ title, index }: { title: string; index: number }) => (
    <button
      className="w-[166px] p-8 text-[18px] justify-center items-center"
      style={{
        backgroundColor: reason === index ? "#2C3832" : "#E3E3E3",
        textWrap: "nowrap",
        fontFamily: "SUIT-Bold",
        opacity: reason === index ? "" : "50%",
        color: reason === index ? "#B1CED4" : "#2E636E",
      }}
      onClick={() => {
        if (reason === index) {
          setReason(99);
        } else {
          setReason(index);
        }
      }}
    >
      ✔ {title}
    </button>
  );
  const CancelApi = async () => {
    setOnLoading(true);
    const formatDate = (date: any) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 1월은 0부터 시작하므로 +1
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${year}.${month}.${day} ${hours}:${minutes}`;
    };
    const now = formatDate(new Date());
    const cancel =
      reason === 1
        ? "재고소진"
        : reason === 2
        ? "숙박시설요청"
        : reason === 3
        ? "고객요청"
        : reason === 4
        ? "기타"
        : "";
    try {
      const result = await CancelOrder(orderId, orderStatus, now, cancel, "Y");
      console.log("result", result);
      setOnLoading(false);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
      setOnLoading(false);
    }
  };
  return (
    <Modal setIsOpen={setIsOpen}>
      <div className="flex flex-col gap-8">
        <div className="flex justify-end pr-4 ">
          <img
            src="/images/icon/close.svg"
            className="w-6 h-6 cursor-pointer"
            alt="close"
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </div>
        <div className="flex flex-col gap-8 ">
          <div className="flex flex-col gap-4 justify-center items-center">
            <div className="text-[32px]" style={{ fontFamily: "SUIT-Bold" }}>
              주문을 거절하시겠습니까?
            </div>
            <div
              className="text-base opacity-50"
              style={{ fontFamily: "SUIT-Medium" }}
            >
              거절 이유를 선택해 주세요.
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-4 pl-4 pr-4 justify-center">
          <Button title="재고소진" index={1} />

          <Button title="숙박시설요청" index={2} />
          <Button title="고객요청" index={3} />
          <Button title="기타" index={4} />
        </div>
        <div className="flex flex-row gap-4 pl-4 pr-4 justify-center">
          <HalfBtnClose setIsOpen={setIsOpen} />
          <HalfBtn onClick={CancelApi} text="확인" />
          {/* <button
            className="pt-[22px] pb-[22px] justify-center items-center w-1/2 text-2xl text-white"
            style={{ backgroundColor: "#EC745A", fontFamily: "SUIT-Bold" }}
            onClick={() => {
              CancelApi();
            }}
          >
            확인
          </button> */}
        </div>
      </div>
    </Modal>
  );
};
