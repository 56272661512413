import { useEffect } from "react";
import { directDelivery } from "../../../@api/service/delivery/delivery";
import { getOrder } from "../../../@api/service/order/order";
import { OrderType } from "../../../types/orderType";

const Request = ({
  orderList,
  setDetailModal,
  setSelectedItem,
  setSelectedOrderId,
  setDeliveryMatchModal,
  setAcceptDeliveryModal,
  setSelectedOrderStatus,
  setCancelModal,
  setOnLoading,
}: {
  orderList: OrderType[];
  setDetailModal: (detailModal: boolean) => void;
  setSelectedItem: (selectedItem: OrderType) => void;
  setSelectedOrderId: (selectedOrderId: number) => void;
  setDeliveryMatchModal: (deliveryMatchModal: boolean) => void;
  setAcceptDeliveryModal: (acceptDeliveryModal: boolean) => void;
  setSelectedOrderStatus: (selectedOrderStatus: string) => void;
  setCancelModal: (cancelModal: boolean) => void;
  setOnLoading: (onLoading: boolean) => void;
}) => {
  const DirectDeliveryApi = async (orderId: number, upDt: string) => {
    setOnLoading(true);
    try {
      const result = await directDelivery(orderId, upDt);
      console.log("직접배달", result);
      setOnLoading(false);
    } catch (error) {
      console.log(error);
      setOnLoading(false);
    }
  };
  const handleAlert = (orderId: number, upDt: string) => {
    const isConfirmed = window.confirm(
      "직접 배달하시겠습니까? 직접배달은 배달취소가 불가능합니다."
    );
    if (isConfirmed) {
      DirectDeliveryApi(orderId, upDt);
    }
  };

  return (
    <div className="flex flex-row flex-wrap gap-4">
      {orderList.length > 0 &&
        orderList.map((item, index) => {
          return (
            <div
              key={index}
              className="flex flex-col gap-6 p-4 w-[280px]  justify-between"
              style={{ border: "1px solid #E3E3E3" }}
            >
              <div className="flex flex-col gap-6">
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col">
                    <div
                      className="text-base"
                      style={{ fontFamily: "SUIT-ExtraBold" }}
                    >
                      {item.hotelName}
                    </div>
                    <div
                      className="text-base"
                      style={{ fontFamily: "SUIT-ExtraBold" }}
                    >
                      {item.roomNum}호
                    </div>
                    <div
                      className="text-base"
                      style={{ fontFamily: "SUIT-ExtraBold" }}
                    >
                      # {item.orderId}
                    </div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div
                      style={{ fontFamily: "SUIT-ExtraBold", fontSize: "32px" }}
                    >
                      {item.orderDate.split(" ")[1].slice(0, 5)}
                    </div>
                  </div>
                </div>
                {item.orderItemList.map((item2, index2) => {
                  return (
                    <div className="flex flex-col">
                      <div className="flex flex-row justify-between">
                        <div
                          className="text-base"
                          style={{ fontFamily: "SUIT-ExtraBold" }}
                        >
                          {item2.itemName}
                        </div>
                        <div
                          className="text-base"
                          style={{ fontFamily: "SUIT-ExtraBold" }}
                        >
                          1
                        </div>
                      </div>
                      {item2.orderItemOption.length > 0 &&
                        item2.orderItemOption.map((item3, index3) => {
                          return (
                            <div
                              className="flex flex-row pl-2 gap-2"
                              style={{ opacity: "50%" }}
                            >
                              <div className="flex items-start">
                                <img src="/images/main/option.svg" alt="옵션" />
                              </div>
                              <div
                                className="text-sm"
                                style={{ fontFamily: "SUIT-Medium" }}
                              >
                                {item3.optionName}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
              </div>
              <div className="flex-col flex gap-2">
                <button
                  className="text-base pt-4 pb-4"
                  style={{
                    backgroundColor: "#FFE4D6",
                    fontFamily: "SUIT-ExtraBold",
                  }}
                  onClick={(e) => {
                    if (item.orderStatus === "00") {
                      setSelectedOrderId(item.orderId);
                      setSelectedItem(item);
                      setDetailModal(true);
                      setSelectedOrderId(item.orderId);
                    } else if (
                      item.orderStatus === "10" ||
                      item.orderStatus === "11"
                    ) {
                      setSelectedOrderId(item.orderId);
                      setDeliveryMatchModal(true);
                    } else if (item.orderStatus === "30") {
                      setAcceptDeliveryModal(true);
                      setSelectedOrderStatus(item.orderStatus);
                      setSelectedOrderId(item.orderId);
                    } else if (item.orderStatus === "32") {
                      setAcceptDeliveryModal(true);
                    } else if (item.orderStatus === "53") {
                      setAcceptDeliveryModal(true);
                    }
                  }}
                >
                  {item.orderStatus === "00"
                    ? "주문 접수중"
                    : item.orderStatus === "10" || item.orderStatus === "11"
                    ? "배달 대기중"
                    : item.orderStatus === "30"
                    ? item.expectedPickupTime.split(" ")[1] + " 픽업 예정"
                    : item.orderStatus === "32"
                    ? `${item.expectedPickupTime.split(" ")[1]} 픽업 예정`
                    : item.orderStatus === "53" || item.orderStatus === "94"
                    ? "배달중"
                    : ""}
                </button>
                {(item.orderStatus === "00" ||
                  item.orderStatus === "30" ||
                  item.orderStatus === "10" ||
                  item.orderStatus === "11") && (
                  <button
                    className="text-base pt-4 pb-4"
                    style={{
                      backgroundColor: "#EC745A",
                      fontFamily: "SUIT-ExtraBold",
                      color: "#FFFFFF",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedOrderId(item.orderId);
                      setSelectedOrderStatus(item.orderStatus);
                      if (item.orderStatus === "00") {
                        setCancelModal(true);
                      } else if (
                        item.orderStatus === "10" ||
                        item.orderStatus === "11"
                      ) {
                        handleAlert(item.orderId, item.uptDt);
                      } else if (item.orderStatus === "30") {
                        setAcceptDeliveryModal(true);
                      }
                    }}
                  >
                    {item.orderStatus === "00"
                      ? "주문취소"
                      : item.orderStatus === "10" || item.orderStatus === "11"
                      ? "직접배달"
                      : "배달 요청취소"}
                  </button>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Request;
