import { request } from "../../request"



export const getDeliveryList = async (orderId:number) => {
    try {
        const result = await request.get(`order/getDeliveryFeeAjax?orderId=${orderId}`)
        return result.data
    } catch (error) {
        console.log(error)
    }
}

export const askDelivery = async (orderId:number,pickMin:string,deliveryCode:string) => {
    try {
        const result = await request.post(`order/requestDelivery?orderId=${orderId}&pickMin=${pickMin}&deliveryCode=${deliveryCode}`)
        return result.data
    } catch (error) {
        console.log(error)
    }
}

export const cancelDelivery = async (orderId : number) => {
    try {
        const result = await request.post(`order/cancelDelivery?orderId=${orderId}`)
        return result.data
    } catch (error) {
        console.log(error)
    }
}

export const directDelivery = async (orderId:number,upDt:string) => {
    try {
        const result = await request.post(`/order/updateOrderStatusAjax?orderId=${orderId}&orderStatus=90&uptDt=${upDt}&isPrint=Y`)
        return result.data
    } catch (error) {
        console.log(error)
    }
}