import axios, { AxiosRequestConfig, AxiosResponse,AxiosRequestHeaders } from 'axios';


// Axios 기본 설정
const axiosInstance = axios.create({
  baseURL:process.env.REACT_APP_HOST , // API 기본 URL
  timeout: 5000, // 타임아웃 설정 (ms)
  headers: {
    'Content-Type': 'application/json',
  },
});

// 요청 인터셉터
axiosInstance.interceptors.request.use(
  (config:any) => {
    const token = localStorage.getItem('accessToken')
    
     if (token) {
        config.headers = config.headers || {};
        (
          config.headers as AxiosRequestHeaders
        ).Authorization = `Bearer ${token}`;
      }
    // 필요한 요청 가로채기 로직 추가 (예: 토큰 추가)
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 응답 인터셉터
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    
    // if(response.data.message===603) {
    //       localStorage.removeItem("accessToken");
    //   alert("토큰이 만료되었습니다. 로그인을 다시 해주세요.")
    //      window.location.href = "/login"; // 또는 다른 리다이렉트 방법
    // }
    return response;
  },
  (error) => {
     if (
      error.response &&
      error.response.data &&
      error.response.data.message==="604"
    ) {
      // // 로컬 스토리지에서 토큰 제거 (선택 사항)
      // localStorage.removeItem("accessToken");
      // alert("토큰이 만료되었습니다. 로그인을 다시 해주세요.")
      // // 로그인 페이지로 리디렉트
      // window.location.href = "/login"; // 또는 다른 리다이렉트 방법
    }  
    return Promise.reject(error);
  }
);

// HTTP 메서드 정의
const get = async (url: string, config?: AxiosRequestConfig) => {
  return axiosInstance.get(url, config);
};

const post = async (url: string, data?: any, config?: AxiosRequestConfig) => {
  return axiosInstance.post(url, data, config);
};

const put = async (url: string, data?: any, config?: AxiosRequestConfig) => {
  return axiosInstance.put(url, data, config);
};

const del = async (url: string, config?: AxiosRequestConfig) => {
  return axiosInstance.delete(url, config);
};

// 다른 파일에서 쉽게 사용할 수 있도록 export
export const request = {
  get,
  post,
  put,
  delete: del,
};