import { useEffect, useState } from "react";
import {
  askDelivery,
  getDeliveryList,
} from "../../@api/service/delivery/delivery";
import { Modal } from "../../component/Modal";
import { DeliveryListType } from "../../types/orderType";

export const DeliveryMatchModal = ({
  isOpen,
  setIsOpen,
  orderId,
  setAcceptDeliveryModal,
  setOnLoading,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setAcceptDeliveryModal: (acceptDeliveryModal: boolean) => void;
  orderId: number;
  setOnLoading: (onLoading: boolean) => void;
}) => {
  console.log("orderid", orderId);
  const [deliveryList, setDeliveryList] = useState<DeliveryListType[]>();

  console.log("orderId", orderId);
  const [deliveryCode, setDeliveryCode] = useState("");
  const [pickMin, setPickMin] = useState("");
  const getDeliveryListApi = async () => {
    setOnLoading(true);
    try {
      const result = await getDeliveryList(orderId);
      setDeliveryList(result.data);
      console.log("deliveryList", result);
      setOnLoading(false);
    } catch (error) {
      console.log(error);
      setOnLoading(false);
    }
  };
  useEffect(() => {
    getDeliveryListApi();
  }, []);

  const askDeliveryApi = async () => {
    setOnLoading(true);
    if (!pickMin) {
      alert("시간을 선택해주세요.");
      setOnLoading(false);
    } else {
      if (deliveryCode !== "") {
        try {
          const result = await askDelivery(orderId, pickMin, deliveryCode);
          if (result.message === "성공") {
            setAcceptDeliveryModal(true);
            setIsOpen(false);
          } else {
            alert("배달 요청을 실패하였습니다.");
          }
          console.log("ask", result);
          setOnLoading(false);
        } catch (error) {
          console.log(error);
          setOnLoading(false);
        }
      } else {
        alert("업체를 확인해주세요.");
        setOnLoading(false);
      }
    }
  };

  const TimeBtn = ({ time }: { time: string }) => (
    <button
      className="p-8 justify-center items-center flex text-lg w-1/2 rounded-lg"
      style={{
        fontFamily: "SUIT-Bold",
        border: "1px solid #E7E5E4",
        backgroundColor: time === pickMin ? "#BFDBFE" : "",
      }}
      onClick={() => {
        setPickMin(time);
      }}
    >
      {pickMin === time ? "✓" : ""} {time}분
    </button>
  );

  return (
    <Modal setIsOpen={setIsOpen}>
      <div className="flex justify-end pr-4 pb-4">
        <img
          src="/images/icon/close.svg"
          alt="close"
          className="cursor-pointer"
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </div>
      <div className="flex flex-col pl-8 pr-8 gap-8">
        <div className="flex-row gap-4 flex">
          <div className="flex flex-col w-1/2">
            {deliveryList &&
              deliveryList.map((item, index) => {
                return (
                  <div
                    className="flex flex-row justify-between pt-6 pb-6 pl-4 pr-4 cursor-pointer"
                    style={{
                      backgroundColor:
                        deliveryCode === item.deliveryCode ? "#E2E8F0" : "",
                    }}
                    onClick={() => {
                      setDeliveryCode(item.deliveryCode);
                    }}
                  >
                    <div
                      className="text-lg"
                      style={{ fontFamily: "SUIT-Bold" }}
                    >
                      {item.deliveryName}
                    </div>
                    <div
                      className="text-lg"
                      style={{ fontFamily: "SUIT-Bold" }}
                    >
                      {Number(item.deliveryFee).toLocaleString()}원
                    </div>
                  </div>
                );
              })}
          </div>

          <div className="flex flex-col gap-2 w-1/2">
            <div className="flex flex-row gap-2 w-full">
              <TimeBtn time="5" />
              <TimeBtn time="10" />
            </div>
            <div className="flex flex-row gap-2 w-full">
              <TimeBtn time="15" />
              <TimeBtn time="20" />
            </div>
            <div className="flex flex-row gap-2 w-full">
              <TimeBtn time="25" />
              <TimeBtn time="30" />
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-4 pl-4 pr-4">
          <button
            className="pt-[22px] pb-[22px] justify-center items-center w-1/2 text-2xl"
            style={{ border: "1px solid #2C3832", fontFamily: "SUIT-Bold" }}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            닫기
          </button>
          <button
            className="pt-[22px] pb-[22px] justify-center items-center w-1/2 text-2xl text-white"
            style={{ backgroundColor: "#EC745A", fontFamily: "SUIT-Bold" }}
            onClick={() => {
              askDeliveryApi();
            }}
          >
            배달 요청하기
          </button>
        </div>
      </div>
    </Modal>
  );
};
