import { useEffect, useState } from "react";
import {
  getSetting,
  setSetting,
  updateSetting,
} from "../../@api/service/setting/setting";
import { useStoreStore } from "../../store/storeStore";
import { parseJsonText } from "typescript";
import { json } from "stream/consumers";
import axios from "axios";

export const Setting = () => {
  const [port, setPort] = useState("");
  const [baudRate, setBaudRate] = useState("");
  const [preBaudRate, setPreBaudRate] = useState("");
  const [tempVoiceCnt, setTempVoiceCnt] = useState(1);
  const { storeId, setStoreId } = useStoreStore();
  const {
    storePort,
    storeBaud,
    setStoreBaud,
    setStorePort,
    setVoiceCnt,
    voiceCnt,
  } = useStoreStore();

  const getSettingApi = async () => {
    try {
      const result = await getSetting(storeId);
      console.log(result.data);

      // 처음에 렌더링 되자마자 바뀔 속도랑 이전 속도 둘다 같이 적용
      // 이후 select에서 baudrate만 change 되고
      // 저장 api탈때 prebaudrate랑 baudrate값을 같이 보냄
      if (!result.data.storeId) {
        setPreBaudRate("");
        setBaudRate("");
        setPort("");
        setTempVoiceCnt(1);
      } else {
        setPreBaudRate(result.data.deviceIp);
        setBaudRate(result.data.deviceIp);
        setPort(result.data.devicePort);
        setTempVoiceCnt(result.data.soundCount);
      }

      console.log("setting", result);
    } catch (error) {
      console.log(error);
    }
  };

  const setSettingApi = async () => {
    try {
      const result = await setSetting(storeId, baudRate, port, tempVoiceCnt);
      console.log("set setting", result.data);
      if (result.message === "성공") {
        getSettingApi();
        setStoreBaud(baudRate);
        setStorePort(port);
        setVoiceCnt(tempVoiceCnt);
        alert("저장 되었습니다.");
      } else {
        alert("설정을 실패하였습니다.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateSettingApi = async () => {
    try {
      const result = await updateSetting(
        storeId,
        preBaudRate,
        port,
        baudRate,
        tempVoiceCnt
      );
      if (result.message === "성공") {
        getSettingApi();
        setStoreBaud(baudRate);
        setStorePort(port);
        setVoiceCnt(tempVoiceCnt);
        alert("변경 되었습니다.");
      }
      console.log("update", result);
    } catch (error) {
      console.log(error);
    }
  };

  const printData = async (
    order,
    port = storePort,
    baudRate = storeBaud,
    cut = true,
    type: string
  ) => {
    try {
      const response = await axios.post("http://localhost:5000/print", {
        port: port,
        baudRate: baudRate,
        data: order, // order 정보를 API에 포함
        cut: cut, // 커팅 여부
        type: type,
      });
      console.log(response.data); // 서버 응답 출력
    } catch (error) {
      console.error("Error printing:", error);
    }
  };

  useEffect(() => {
    getSettingApi();
  }, []);

  return (
    <div className="flex flex-col pt-8 pl-4 pr-4 w-full">
      <div className="flex flex-col gap-4">
        <div className="text-2xl" style={{ fontFamily: "SUIT-Bold" }}>
          설정
        </div>
        <div className="flex flex-col gap-4 pt-4">
          <div className="text-sm" style={{ fontFamily: "SUIT-Medium" }}>
            라벨
          </div>
          <div className="flex flex-row gap-2">
            <div className="flex flex-col gap-1">
              <select
                value={port || ""}
                onChange={(e) => {
                  setPort(e.target.value);
                }}
                className="w-[291px] pt-4 pl-2 pr-2 pb-4  text-base"
                style={{
                  border: "1px solid #B6B6B6",
                  fontFamily: "SUIT-Medium",
                }}
              >
                <option value="">포트 설정</option>
                {Array.from({ length: 20 }, (_, i) => (
                  <option key={i + 1}>{`COM${i + 1}`}</option>
                ))}
              </select>
              <select
                value={baudRate || ""}
                onChange={(e) => {
                  setBaudRate(e.target.value);
                }}
                className="w-[291px] pt-4 pl-2 pr-2 pb-4  text-base"
                style={{
                  border: "1px solid #B6B6B6",
                  fontFamily: "SUIT-Medium",
                }}
              >
                <option value="">속도 설정</option>
                <option>9600</option>
                <option>13200</option>
                <option>19200</option>
                <option>38400</option>
                <option>57600</option>
                <option>115200</option>
              </select>
            </div>

            <button
              onClick={() => {
                if (baudRate === "") return alert("속도를 선택해주세요.");
                if (port === "") return alert("포트를 선택해주세요.");
                if (preBaudRate === "") {
                  setSettingApi();
                } else {
                  updateSettingApi();
                }
              }}
              className="p-4 w-fit h-fit text-base text-white"
              style={{ backgroundColor: "black", fontFamily: "SUIT-ExtraBold" }}
            >
              저장
            </button>
            <button
              onClick={() => {
                printData(
                  {
                    orderId: 0,
                    hotelId: 0,
                    hotelName: "",
                    hotelAddress: "",
                    hotelPhone: "",
                    storeId: 0,
                    storeName: "",
                    storePhone: "",
                    storeAddress: "",
                    roomNum: "",
                    userName: "",
                    userPhone: "",
                    deliveryAddress: "",
                    deliveryDetailAddress: "",
                    orderStatus: "",
                    orderItemList: [], // 빈 배열로 초기화
                    orderAmount: 0,
                    discountAmount: 0,
                    deliveryTip: 0,
                    payAmount: 0,
                    payMethod: "",
                    cardName: "",
                    cardNumber: "",
                    payAuthNo: "",
                    payInfo: "",
                    deliveryFee: "",
                    orderMemo: "",
                    deliveryMemo: "",
                    orderDate: "",
                    reservationDate: "",
                    reservationList: "",
                    acceptDate: "",
                    readyTime: 0,
                    estimateTime: "",
                    cancelDate: "",
                    deliveryStartDate: "",
                    deliveryEndDate: "",
                    cancelInfo: "",
                    isPrint: "",
                    orderType: "",
                    typeKey: "",
                    cretDt: "",
                    uptDt: "",
                    updateDate: "",
                    cancelFormatDate: "",
                    expectedPickupTime: "",
                    rowNum: 0,
                    mainItemName: "",
                    itemCount: 0,
                    orderStatusNm: "",
                    expectedPickupTimeR: "",
                  },
                  storePort,
                  storeBaud,
                  true,
                  "test"
                );
              }}
              className="p-4 w-fit h-fit text-base text-black"
              style={{
                backgroundColor: "white",
                fontFamily: "SUIT-ExtraBold",
                border: "1px solid #000000",
              }}
            >
              영수증 데모 인쇄
            </button>
          </div>
          <div className="flex flex-row gap-2">
            <select
              value={tempVoiceCnt}
              onChange={(e) => {
                setTempVoiceCnt(Number(e.target.value));
              }}
              className="w-[291px] pt-4 pl-2 pr-2 pb-4  text-base"
              style={{ border: "1px solid #B6B6B6", fontFamily: "SUIT-Medium" }}
            >
              <option disabled>주문 목소리를 선택해 주세요.</option>

              {Array.from({ length: 20 }, (_, i) => (
                <option key={i + 1}>{i + 1}</option>
              ))}
            </select>
            <button
              className="p-4 w-fit h-fit text-base text-white"
              style={{ backgroundColor: "black", fontFamily: "SUIT-ExtraBold" }}
              onClick={() => {
                const playSound = async () => {
                  for (let i = 0; i < tempVoiceCnt; i++) {
                    const audio = new Audio("/sounds/orderSound.mp3");
                    await audio.play();
                    // 소리가 끝날 때까지 대기
                    await new Promise((resolve) => {
                      audio.onended = resolve;
                    });
                  }
                };
                playSound();
              }}
            >
              주문 목소리 재생
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
