import { ReactNode } from "react";

export const Modal = ({
  setIsOpen,
  children,
}: {
  setIsOpen: (isOpen: boolean) => void;
  children: ReactNode;
}) => {
  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={() => {
        setIsOpen(false);
      }}
    >
      <div
        className="bg-white rounded-lg shadow-lg w-4/5  pt-4 pb-[60px]"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};
