import { OrderType } from "../../../types/orderType";

const Complete = ({ orderList }: { orderList: OrderType[] }) => {
  return (
    <div className="flex flex-row flex-wrap gap-4">
      {orderList.length > 0 &&
        orderList.map((item, index) => {
          return (
            <div
              key={index}
              className="flex flex-col gap-6 p-4 w-[280px]  justify-between"
              style={{ border: "1px solid #E3E3E3" }}
            >
              <div className="flex flex-col gap-6">
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col opacity-30">
                    <div
                      className="text-base"
                      style={{ fontFamily: "SUIT-ExtraBold" }}
                    >
                      {item.hotelName}
                    </div>

                    <div
                      className="text-base"
                      style={{ fontFamily: "SUIT-ExtraBold" }}
                    >
                      {item.roomNum}호
                    </div>
                    <div
                      className="text-base"
                      style={{ fontFamily: "SUIT-ExtraBold" }}
                    >
                      # {item.orderId}
                    </div>
                  </div>
                  <div
                    className="opacity-30"
                    style={{ fontFamily: "SUIT-ExtraBold", fontSize: "32px" }}
                  >
                    {item.orderDate.split(" ")[1].slice(0, 5)}
                  </div>
                </div>
                {item.orderItemList.map((item2, index2) => {
                  return (
                    <div key={index2} className="flex flex-col">
                      <div className="flex flex-row justify-between">
                        <div
                          className="text-base"
                          style={{ fontFamily: "SUIT-ExtraBold" }}
                        >
                          {item2.itemName}
                        </div>
                        <div
                          className="text-base"
                          style={{ fontFamily: "SUIT-ExtraBold" }}
                        >
                          {item2.qty}
                        </div>
                      </div>
                      {item2.orderItemOption.length > 0 &&
                        item2.orderItemOption.map((item3, index3) => {
                          return (
                            <div
                              key={index3}
                              className="flex flex-row pl-2 gap-2"
                              style={{ opacity: "50%" }}
                            >
                              <div className="flex items-start">
                                <img src="/images/main/option.svg" alt="옵션" />
                              </div>
                              <div
                                className="text-sm"
                                style={{ fontFamily: "SUIT-Medium" }}
                              >
                                {item3.optionName}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
              </div>

              <div
                className="flex pt-4 pb-4 justify-center items-center"
                style={{
                  border: "1px solid #000000",
                }}
              >
                <div
                  className="text-base"
                  style={{ fontFamily: "SUIT-ExtraBold" }}
                >
                  배달 완료
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Complete;
